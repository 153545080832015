/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from "immer";

// The initial state

export const initialState = {
  authToken: null,
  authprefill: {
    email: "",
    company: "",
    role: "property_developer",
    invitation_code: "",
    firm_id: null,
  },
  changePasswordModalShow: false,
  collaboratorEditModalShow: false,
  collaboratorInviteModalShow: false,
  collaboratorList: [],
  collaboratorSuggestionsSearchResults: [],
  collaboratorView: 'full',
  dashboardView: 'private',
  estateAgencySuggestionsSearchResults: [],
  firmEditModalShow: false,
  inProgressSignup: false,
  inProgressLogin: false,
  inProgressFirmUpdate: false,
  inProgressResetPassword: false,
  inProgressChangePassword: false,
  loggedInUser: null,
  loginModalShow: false,
  myProfileEditModalShow: false,
  plotAddModalShow: false,
  nestingModelShow: false,
  plotEditModalShow: false,
  plotList: [],
  plotView: 'full',
  productAddModalShow: false,
  productEditModalShow: false,
  productShareModalShow: false,
  productList: [],
  resetPasswordSent: false,
  loginSuccess: false,
  roles: [],
  selectedCollaborator: undefined,
  selectedCollaboratorId: undefined,
  selectedFirm: {
    id: null,
    name: null,
    logo: null,
    domain: "",
    account_id: null,
  },
  propertyType: ['SITE/place','PLOT/building','UNIT/home','ROOM/space'],
  selectedPlotType: 'SITE/place',
  selectedItem: undefined,
  selectedItemIndex: undefined,
  selectedPlot: undefined,
  selectedPlotId: undefined,
  selectedProduct: undefined,
  selectedProductId: undefined,
  showProductItemAddModal: false,
  showProductItemEditModal: false,
  showProductItemFullViewModal: false,
  signupModalShow: false,
  termsModalShow: false,
  isPropertySelect: false,
  _selectedPLOTID: undefined,
  _selectedbuildingId: undefined,
  _selecthomeId: undefined,
  _selectedRoomId: undefined,
  nestingPropertyList: [],
  _selectedPropertyId: undefined,
  parentPropertyList: undefined,
  parentpropertyname: undefined,
  filterValue: '',
  filterProductCategory: '',
  user_type: undefined,
  siteCount: [],
  plotCount: 0,
  homeount: 0,
  roomCount: 0,
  plotLoading: false,
  _selectedBiulding: undefined,
  _selectedPlot: undefined,
  _selectedHome: undefined,
  _parentSiteName: undefined,
  _parentPlotName: undefined,
  _parentHomeName: undefined,
  Banner: undefined,
  categories: undefined,
  displayCategoryView: false,
  selectedCategoryType: undefined,
  selectedCategory: undefined,
  selectedProductTab: 'product',
  changePasswordModal: {
    confirmPassword: "",
    password: "",
  },
  estateAgencyAutoSuggest: {
    search: '',
    suggestions:[],
  },
  loginModal: {
    email: "",
    resetEmail: "",
    password: "",
    forgotPasswordShow: false,
    recaptchaVerify:false
  },
  myProfileEditModal: {
    name: '',
    image: null,
    isNameChange: false
  },
  signupModal: {
    email: '',
    company: '',
    password:'',
    estateAgency:'',
    estateAgencySuggestion:null,
  },
  collaboratorAutoSuggest: {
    search: '',
    suggestions:[],
  },
  collaboratorEditModal: {
    collaboratorName: '',
    collaboratorRole: '',
    collaboratorEmail: null,
  },
  collaboratorInviteModal: {
    email: '',
    name: '',
    role:'d82bf43b', // viewer, default role/userType
    suggestion: null,
  },
  firmEditModal: {
    firmName: '',
    domain: '',
    logo: null
  },
  plotAddModal: {
    plotName: '',
    plotNumber: '',
    plotImage:null,
    plotType:'',
    parentplotId:'',
    parentChange:false,
    parentplotname:'',
    showparentName:'false'
  },
  plotEditModal: {
    plotName: '',
    plotNumber: '',
    plotImage: null,
    plotid:'',
    isParentPropertyChange:false,
    isChangeImage:false,
    propertyImageremove:false
  },
  productAddModal: {
    productName: '',
    productDescription: '',
    productImages: [],
    imagePreviews: [],
    fillSpecs:'',
    key:'',
    typeSuggestmenuOpen: false,
    categorySuggestmenuOpen: false,
    showDiscritopn: false,
  },
  productEditModal:{
    productName: '',
    productDescription: '',
    speces: undefined,
    selectedCategory: undefined,
    fillSpecs: undefined,
    key: '',
    categorySuggestmenuOpen: false,
    showDiscritopn: false,
  },
  productItemAddModal:{
    productItemTitle: '',
    productItemDescription: '',
    productItemImage: null,
  },
  productItemEditModal:{
    productItemTitle: '',
    productItemDescription: '',
    productItemImage: null,
  },
  productShareModal:{
    email: '',
    suggestion: null,
  },
  productTile: {
    productName: '',
    productDescription: '',
  }
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {

      case 'components/ProductTile/CHANGE_PRODUCT_NAME': {
        draft.productTile.productName = action.productName;
        break;
      }

      case 'components/ProductTile/CHANGE_PRODUCT_DESCRIPTION': {
        draft.productTile.productDescription = action.productDescription;
        break;
      }

      case 'components/ProductShareModal/CHANGE_PRODUCT_SHARE_EMAIL':
          draft.productShareModal.email = action.email;
          break;

      case 'components/ProductShareModal/CHANGE_SUGGESTION':
          draft.productShareModal.suggestion = action.suggestion;
          break;

      case 'components/ProductItemEditModal/CHANGE_PRODUCT_ITEM_TITLE':
        draft.productItemEditModal.productItemTitle = action.productItemTitle;
        break;

      case 'components/ProductItemEditModal/CHANGE_PRODUCT_DESCRIPTION':
        draft.productItemEditModal.productItemDescription = action.productItemDescription;
        break;

      case 'components/ProductItemEditModal/CHANGE_PRODUCT_ITEM_IMAGE':
        if (action.productItemImage) {
          draft.productItemEditModal.productItemImage = action.productItemImage;
          draft.productItemEditModal.productItemImage.preview = URL.createObjectURL(action.productItemImage);
        }
        if (!action.productItemImage && draft.productItemEditModal.productItemImage && draft.productItemEditModal.productItemImage.preview) {
          draft.productItemEditModal.productItemImage = null;
        }
        break;

      case 'components/ProductItemAddModal/CHANGE_PRODUCT_ITEM_TITLE':
        draft.productItemAddModal.productItemTitle = action.productItemTitle;
        break;

      case 'components/ProductItemAddModal/CHANGE_PRODUCT_DESCRIPTION':
        draft.productItemAddModal.productItemDescription = action.productItemDescription;
        break;

      case 'components/ProductItemAddModal/CHANGE_PRODUCT_ITEM_IMAGE':
        draft.productItemAddModal.productItemImage = action.productItemImage;
        break;

      case 'components/ProductEditModal/CHANGE_PRODUCT_NAME':
        draft.productEditModal.productName = action.productName;
        break;

      case 'components/ProductEditModal/CHANGE_PRODUCT_DESCRIPTION':
        draft.productEditModal.productDescription = action.productDescription;
        break;

      case 'components/ProductEditModal/CHANGE_PRODUCT_SPECS': {
        draft.productEditModal.speces = action.specs;
        break;
      }

      case 'components/ProductTile/CHANGE_DISCRIPTION_SHOW': {
        draft.productEditModal.showDiscritopn = action.status;
        break;
      }

      case 'components/ProductEditModal/ASSIGN_PRODUCT_SPECS': {
        draft.productEditModal.speces = action.speces;
        break;
      }

      case 'components/ProductEditModal/ADD_CATEGORY_CHANGE': {
        draft.productEditModal.selectedCategory = action.category
        draft.productEditModal.fillSpecs = undefined;
        break;
      }

      case 'components/ProductEditModal/CHANGE_CATEGORY_OPEN_STATUS': {
       draft.productEditModal.categorySuggestmenuOpen = action.status
      }

      case 'components/ProductEditModal/FILL_SPECS_OBJECT': {
        draft.productEditModal.fillSpecs = action.specs;
        break;
      }

      case 'components/ProductEditModal/CLICK_TEXT_BOX': {
        draft.productEditModal.key = action.key;
        break;
      }

      case 'components/ProductAddModal/CHANGE_PRODUCT_NAME':
        draft.productAddModal.productName = action.productName;
        break;

      case 'components/ProductAddModal/CHANGE_PRODUCT_DESCRIPTION':
        draft.productAddModal.productDescription = action.productDescription;
        break;

      case 'components/ProductTile/CHANGE_TYPE_OPEN_STATUS': {
        break;
      }

      case 'components/ProductTile/CHANGE_DISCRIPTION_SHOW': {
        draft.productAddModal.showDiscritopn = action.status;
        break;
      }

      case 'components/ProductTile/CHANGE_CATEGORY_OPEN_STATUS': {
        draft.productAddModal.categorySuggestmenuOpen = action.status
        break;
      }

      case 'components/ProductAddModal/CHANGE_PRODUCT_IMAGES':
        draft.productAddModal.productImages = action.productImages;

        if(action.productImages) {
            draft.productAddModal.imagePreviews = [...action.productImages].map(file =>
                file ? { preview: URL.createObjectURL(file) } : file,
            );
        } else if ( draft.productAddModal.imagePreviews){
            draft.productAddModal.imagePreviews.map(preview =>
                URL.revokeObjectURL(preview.preview),
            );
            draft.productAddModal.imagePreviews = null;
        }
        break;

      case 'components/ProductAddModal/CLEAR_IMAGE_PREVIEWS': {
        if(!draft.productAddModal.imagePreviews){
          break;
        }
        draft.productAddModal.imagePreviews.map(preview =>
          URL.revokeObjectURL(preview.preview),
        );
        draft.productAddModal.imagePreviews = null;
        break;
      }

      case 'components/ProductTile/FILL_SPECS_OBJECT': {
        draft.productAddModal.fillSpecs = action.specs;
        break;
      }

      case 'components/ProductTile/CLICK_TEXT_BOX': {
        draft.productAddModal.key = action.key;
        break;
      }

      case 'components/PlotEditModal/CHANGE_PLOT_PARENT':
        draft.plotEditModal.plotid = action.parentId;
        draft.plotEditModal.isParentPropertyChange = true
        break;

      case 'components/PlotEditModal/REMOVE_IMAGE':
        draft.plotEditModal.propertyImageremove = true
        break;

      case 'components/PlotEditModal/CHANGE_PLOT_NAME':
        draft.plotEditModal.plotName = action.plotName;
        break;

      case 'components/PlotEditModal/CHANGE_PRODUCT_NUMBER':
        draft.plotEditModal.plotNumber = action.plotNumber;
        break;

      case 'components/PlotEditModal/CHANGE_PLOT_IMAGE':
        if(action.plotImage === 'removeImage'){
          draft.plotEditModal.plotImage = 'removed';
          draft.plotEditModal.isChangeImage = true;
          break;
        }
        if(action.plotImage === null){
          draft.plotEditModal.plotImage = null;
          draft.plotEditModal.isChangeImage = false;
        }
        if (action.plotImage) {
          draft.plotEditModal.plotImage = action.plotImage;
          draft.plotEditModal.plotImage.preview = URL.createObjectURL(action.plotImage);
          draft.plotEditModal.isChangeImage = true;
        }
        if (!action.plotImage && draft.plotEditModal.plotImage && draft.plotEditModal.plotImage.preview) {
          draft.plotEditModal.plotImage = null;
        }
        break;

      case 'components/PlotAddModal/CHANGE_PLOT_NAME':
          draft.plotAddModal.plotName = action.plotName;
          if(action.plotName === ""){
              draft.plotType = '';
              draft.parentplotId = '';
              draft.parentChange = false;
          }
          break;

      case 'components/PlotAddModal/CHANGE_PLOT_NUMBER':
          draft.plotAddModal.plotNumber = action.plotNumber;
          break;

      case 'components/PlotAddModal/CHANGE_PLOT_IMAGE':
          draft.plotAddModal.plotImage = action.plotImage;
          break;

      case 'components/PlotAddModal/CHANGE_PLOT_TYPE':
          draft.plotAddModal.plotType = action.plotType;
          draft.plotAddModal.parentChange = true;
          draft.plotAddModal.showparentName = true;
          draft.plotAddModal.parentplotId = action.nestedId;
          draft.plotAddModal.parentplotname = action.plotname;
      break;

      case 'App/FILTER_NESTED_MAP':
          draft.plotAddModal.showparentName = false;
      break;

      case 'components/PlotAddModal/DISCARD_PARENT_CHANGE':
          draft.plotAddModal.plotType = action.plotType;
          draft.plotAddModal.parentChange = false;
          draft.plotAddModal.showparentName = false;
      break;

      case 'components/FirmEditModal/CHANGE_FIRM_NAME':
          draft.firmEditModal.firmName = action.firmName;
          break;

      case 'components/FirmEditModal/CHANGE_DOMAIN':
          draft.firmEditModal.domain = action.domain;
          break;

      case 'components/FirmEditModal/CHANGE_LOGO':
          draft.firmEditModal.logo = action.logo;
          break;

      case 'components/Collaborator/CollaboratorInviteModal/CHANGE_EMAIL':
          draft.collaboratorInviteModal.email = action.email;
          break;

      case 'components/Collaborator/CollaboratorInviteModal/CHANGE_SUGGESTION':
          draft.collaboratorInviteModal.suggestion = action.suggestion;
          break;

      case 'components/Collaborator/CollaboratorInviteModal/CHANGE_NAME':
          draft.collaboratorInviteModal.name = action.name;
          break;

      case 'components/Collaborator/CollaboratorInviteModal/CHANGE_ROLE':
          draft.collaboratorInviteModal.role = action.role;
          break;

      case 'components/CollaboratorEditModal/CHANGE_COLLABORATOR_NAME':
        draft.collaboratorEditModal.collaboratorName = action.collaboratorName;
        break;

      case 'components/CollaboratorEditModal/CHANGE_COLLABORATOR_ROLE':
        draft.collaboratorEditModal.collaboratorRole = action.collaboratorRole;
        break;

      case 'components/CollaboratorEditModal/CHANGE_COLLABORATOR_EMAIL':
          draft.collaboratorEditModal.collaboratorEmail = action.collaboratorEmail;
        break;

      case 'components/Collaborator/CollaboratorAutoSuggest/CHANGE_SEARCH':
          draft.collaboratorAutoSuggest.search = action.search;
          break;

      case 'components/Collaborator/CollaboratorAutoSuggest/CHANGE_SUGGESTIONS':
          draft.collaboratorAutoSuggest.suggestions = action.suggestions;
          break;

      case 'components/Authentication/SignupModal/CHANGE_EMAIL':
          draft.signupModal.email = action.email;
          break;

      case 'components/Authentication/SignupModal/CHANGE_COMPANY':
          draft.signupModal.company = action.company;
          break;

      case 'components/Authentication/SignupModal/CHANGE_PASSWORD':
          draft.signupModal.password = action.password;
          break;

      case 'components/Authentication/SignupModal/CHANGE_ESTATE_AGENCY': {
          draft.signupModal.estateAgency  = action.estateAgency;
          break;
      }

      case 'components/Authentication/SignupModal/CHANGE_ESTATE_AGENCY_SUGGESTION': {
          draft.signupModal.estateAgencySuggestion  = action.suggestion;
          break;
      }

      case 'components/MyProfileEditModal/CHANGE_NAME':
          draft.myProfileEditModal.name = action.name;
          draft.myProfileEditModal.isNameChange = true
          break;

      case 'components/MyProfileEditModal/CHANGE_IMAGE':
          draft.myProfileEditModal.image = action.image;
          break;

      case 'components/MyProfileEditModal/IS_CHANGE_NAME':
          draft.myProfileEditModal.isNameChange = false
          break;

      case 'components/EstateAgency/EstateAgencyAutoSuggest/CHANGE_SEARCH':
          draft.estateAgencyAutoSuggest.search = action.search;
          break;

      case 'components/EstateAgency/EstateAgencyAutoSuggest/CHANGE_SUGGESTIONS':
          draft.estateAgencyAutoSuggest.suggestions = action.suggestions;
          break;

      case 'components/Authentication/ChangePasswordModal/CHANGE_CONFIRM_PASSWORD':
          draft.changePasswordModal.confirmPassword = action.confirmPassword;
          break;

      case 'components/Authentication/ChangePasswordModal/CHANGE_PASSWORD':
          draft.changePasswordModal.password = action.password;
          break;

      case 'components/Authentication/LoginModal/CHANGE_EMAIL':
          draft.loginModal.email = action.email;
          break;

      case 'components/Authentication/LoginModal/CHANGE_RESET_EMAIL':
          draft.loginModal.resetEmail = action.resetEmail;
          break;

      case 'components/Authentication/LoginModal/CHANGE_PASSWORD':
          draft.loginModal.password = action.password;
          break;

      case 'components/Authentication/LoginModal/CHANGE_RECAPTCHA_VERIFY':
          draft.loginModal.recaptchaVerify = action.recaptchaVerify;
          break;

      case 'App/FETCH_NESTING_SUCCESS_PROPERTYLIST':{
        draft.nestingPropertyList = action.response;
        break;
      }
      
      case 'App/UNSELECT_CATEGORY': {
        draft.selectedCategoryType = undefined;
        draft.selectedCategory = undefined;
        break;
      }
      
      case 'App/PRODUCT_FILTER_NAME': {
        draft.filterProductCategory = action.val;
        draft.selectedCategoryType = undefined;
        draft.selectedCategory = undefined;
        break;
      }
      
      case 'App/PLOT_FETCH_SUBMIT': {
        draft.plotLoading = true
        break;
      }
      
      case 'App/CHANGE_CATEGORY_TYPE_SUGGEST': {
        if(action.object === ''){
          draft.selectedCategory = undefined
          draft.selectedCategoryType = undefined
        } else {
          draft.selectedCategory = action.object
          draft.selectedCategoryType = action.object.categoryType
        }
        break;
      }
      
      case 'App/SELECT_CATEGORY_TYPE': {
        draft.displayCategoryView = action.bool
        draft.selectedCategoryType = action.show
        if(action.show === ''){
          draft.selectedCategoryType = undefined
        }
        break;
      }
      
      case 'App/CHANGE_PRODUCT_TAB': {
        draft.selectedProductTab = action.name;
        break;
      }
      
      case 'App/PLOT_SELECT_NAME': {
        draft.parentpropertyname = action.plotName;
        break;
      }
      
      case 'App/SIGNUP_MODAL_SHOW':
        draft.signupModalShow = true;
        break;
      
      case 'App/SIGNUP_MODAL_HIDE':
        draft.signupModalShow = false;
        break;
      
      case 'App/TERMS_MODAL_SHOW':
        draft.termsModalShow = true;
        break;
      
      case 'App/TERMS_MODAL_HIDE':
        draft.termsModalShow = false;
        break;
      
      case 'App/PRIVACY_MODAL_SHOW':
        draft.privacyModalShow = true;
        break;
      
      case 'App/PRIVACY_MODAL_HIDE':
        draft.privacyModalShow = false;
        break;
      
      case 'App/SIGNUP_SUBMIT': {
        draft.inProgressSignup = true;
        break;
      }
      
      case 'App/SIGNUP_ERROR': {
        draft.inProgressSignup = false;
        break;
      }
      
      case 'App/FILTER_NESTED_MAP': {
        draft.filterValue = action.suggestName
        break;
      }
      
      case 'App/SIGNUP_SUCCESS': {
        draft.inProgressSignup = false;
        draft.loggedInUser = {
          id: action.user._id,
          name: action.user.name,
          email_md5: action.user.email_md5,
          image: action.user.image,
        };
        draft.authToken = {
          access: action.auth.access_token,
          expires_at: new Date(
            new Date().getTime() + action.auth.expires_in * 1000
          ),
          refresh: action.auth.refresh_token,
        };
        draft.signupModalShow = false;
        draft.selectedFirm = {
          id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          account_id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          name: "4667DB53-7AC9",
          logo: null,
          domain: "BBF62CE7-6AEA",
          type: "property_developer",
          logo_full_url: null,
        };
        draft.authprefill = initialState.authprefill;
        break;
      }

      case 'App/LOGIN_MODAL_SHOW':
        draft.loginModalShow = true;
        break;

      case 'App/LOGIN_MODAL_HIDE':
        draft.loginModalShow = false;
        break;

      case 'App/LOGIN_SUBMIT': {
        draft.inProgressLogin = true;
        break;
      }

      case 'App/LOGIN_SUCCESS': {
        draft.inProgressLogin = false;
        draft.loginSuccess = true;
        draft.loggedInUser = {
          id: action.user._id,
          name: action.user.name,
          email_md5: action.user.email_md5,
          image: action.user.image,
        };
        draft.authToken = {
          access: action.auth.access_token,
          expires_at: new Date(
            new Date().getTime() + action.auth.expires_in * 1000
          ),
          refresh: action.auth.refresh_token,
        };
        draft.loginModalShow = false;
        draft.selectedFirm = {
          id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          account_id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          name: "4667DB53-7AC9",
          logo: null,
          domain: "BBF62CE7-6AEA",
          type: "property_developer",
          logo_full_url: null,
        };
        draft.authprefill = initialState.authprefill;
        break;
      }
      
      case 'App/LOGIN_ERROR': {
        draft.loginSuccess = false;
        draft.inProgressLogin = false;
        break;
      }
      
      case 'App/RESET_PASSWORD_ERROR': {
        draft.inProgressResetPassword = false;
        draft.resetPasswordSent = false;
        break;
      }
      
      case 'App/RESET_PASSWORD_SUBMIT': {
        draft.inProgressResetPassword = true;
        draft.resetPasswordSent = false;
        break;
      }
      
      case 'App/RESET_PASSWORD_SUCCESS': {
        draft.inProgressResetPassword = false;
        draft.resetPasswordSent = true;
        break;
      }
      
      case 'App/RESET_PASSWORD_RETRY': {
        draft.inProgressResetPassword = false;
        draft.resetPasswordSent = false;
        break;
      }
      
      case 'App/CHANGE_PASSWORD_MODAL_SHOW': {
        draft.changePasswordModalShow = true;
        break;
      }
      
      case 'App/CHANGE_PASSWORD_MODAL_HIDE': {
        draft.changePasswordModalShow = false;
        break;
      }
      
      case 'App/CHANGE_PASSWORD_SUBMIT': {
        draft.inProgressChangePassword = true;
        break;
      }
      
      case 'App/CHANGE_PASSWORD_SUCCESS': {
        draft.inProgressChangePassword = false;
        break;
      }
      
      case 'App/CHANGE_PASSWORD_ERROR': {
        draft.inProgressChangePassword = false;
        break;
      }
      
      case 'App/LOGOUT_SUCCESS': {
        draft = initialState;
        break;
      }
      
      case 'App/AUTH_FETCH_FROM_LOCAL_SUCCESS': {
        draft.loggedInUser = {
          id: action.user.id,
          name: action.user.name,
          email: action.user.maskedEmail,
          email_md5: action.user.email_md5,
          image: action.user.image,
          type: "property_developer"
        };
        draft.authToken = {
          access: action.auth.access,
          expires_at: action.auth.expires_at,
          refresh: action.auth.refresh,
        };
        draft.selectedFirm = {
          id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          account_id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          name: "4667DB53-7AC9",
          logo: null,
          domain: "BBF62CE7-6AEA",
          type: "property_developer",
          logo_full_url: null,
        };
        break;
      }

      case 'App/FIRM_SELECT': {
        if (action.accountId) {
          draft.selectedFirm.account_id = action.accountId;
        }
        if (action.firmId) {
          draft.selectedFirm.id = action.firmId;
        }
        break;
      }

      case 'App/ROLE_FETCH_SUCCESS': {
        draft.roles = action.response.roles.reduce((roles, role) => {
          roles.push({
            roleId: role._id,
            user_id: role.user_id,
            firm_id: role.firm_id,
            role: role.role,
            level: role.level,
          });
          return roles;
        }, []);
        break;
      }

      case 'App/FIRM_EDIT_MODAL_SHOW':
        draft.firmEditModalShow = true;
        break;

      case 'App/FIRM_EDIT_MODAL_HIDE':
        draft.firmEditModalShow = false;
        break;
      
      case 'App/MYPROFILE_EDIT_MODAL_SHOW':
        draft.myProfileEditModalShow = true;
        break;
      
      case 'App/MYPROFILE_EDIT_MODAL_HIDE':
        draft.myProfileEditModalShow = false;
        break;

      case 'App/FIRM_FETCH_SUBMIT': {
        // todo: set loading
        break;
      }

      case 'App/FIRM_FETCH_SUCCESS': {
        draft.selectedFirm = {
          id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          account_id: "2379239A-E29E-4DE3-8A18-CD05DC80EE31",
          name: "4667DB53-7AC9",
          logo: null,
          domain: "BBF62CE7-6AEA",
          type: "property_developer",
          logo_full_url: null,
        };
        break;
      }
      
      case 'App/FIRM_FETCH_ERROR': {
        // draft.selectedFirm = null;
        break;
      }

      case 'App/FIRM_UPDATE_SUBMIT': {
        draft.inProgressFirmUpdate = true;
        break;
      }

      case 'App/FIRM_UPDATE_SUCCESS': {
        draft.inProgressFirmUpdate = false;
        draft.selectedFirm.name = action.response.firm.name;
        draft.selectedFirm.logo = action.response.firm.logo;
        draft.selectedFirm.domain = action.response.firm.domain;
        break;
      }

      case 'App/FIRM_UPDATE_ERROR': {
        draft.inProgressFirmUpdate = false;
        break;
      }
      
      case 'App/MYPROFILE_UPDATE_SUBMIT': {
        draft.inProgressMyProfileUpdate = true;
        break;
      }
      
      case 'App/MYPROFILE_UPDATE_SUCCESS': {
        draft.inProgressMyProfileUpdate = false;
        if (draft.loggedInUser) {
          draft.loggedInUser.name = action.response.user.name;
          draft.loggedInUser.image = action.response.user.image;
        }
        break;
      }
      
      case 'App/MYPROFILE_UPDATE_ERROR': {
        draft.inProgressMyProfileUpdate = false;
        break;
      }
      
      case 'App/PLOT_ADD_MODAL_SHOW':
        draft.plotAddModalShow = true;
        break;
      
      case 'App/PLOT_ADD_MODAL_HIDE':
        draft.plotAddModalShow = false;
        break;
      
      case 'App/NESTING_MODEL_SHOW': {
        if(draft.nestingModelShow === true){
          draft.nestingModelShow = false;
        } else {
          draft.nestingModelShow = true;
        }
        break;
      }

      case 'App/NESTING_MODEL_HIDE':
          draft.nestingModelShow = false;
          break;

      case 'App/PLOT_EDIT_MODAL_SHOW':
        draft.plotEditModalShow = true;
        break;

      case 'App/PLOT_EDIT_MODAL_HIDE':
        draft.plotEditModalShow = false;
        break;

      case 'App/PLOT_CREATE_SUBMIT': {
        // create a new plot and push to the plotList with the tempId
        if(draft){
          if(!draft.isPropertySelect || !draft.plotList){
            draft.plotList.unshift({
              plotId: action.tempPlotId,
              plotName: action.plotName,
              plotNumber: action.plotNumber,
              plotImage: null,
              productList: [],
              collaboratorList: [],
              isCreating: true, // set isCreating to true to show the loading animation
            });
          } else if(draft.isPropertySelect){
            draft.isPropertySelect = false
            //draft.selectedPlotType=draft.propertyType[action.status]
            if(draft.selectedPlotType === "SITE/place"){
              draft.selectedPlotType = "PLOT/building";
            }
            else if(draft.selectedPlotType === "PLOT/building"){
              draft.selectedPlotType = "UNIT/home";
            }
            else if(draft.selectedPlotType === "UNIT/home"){
              draft.selectedPlotType = "ROOM/space";
            }
            else if(draft.selectedPlotType === "ROOM/space"){
              draft.plotList.unshift({
                plotId: action.tempPlotId,
                plotName: action.plotName,
                plotNumber: action.plotNumber,
                plotImage: null,
                productList: [],
                collaboratorList: [],
                isCreating: true, // set isCreating to true to show the loading animation
              });
            }
          }
          // draft.isPropertySelect = false
          break;
        } else {
          break;
        }
      }

      case 'App/PLOT_CREATE_SUCCESS': {
        if(!draft.isPropertySelect || !draft.plotList ){
          draft.isPropertySelect = true
          draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plot.tempId || plot.plotId === action.plot._id
            ? {
                ...plot,
                plotId: action.plot._id,
                plotImage: action.plot.image,
                isCreating: false,
              }
            : plot
        );
        if(draft.selectedPlotType === 'SITE/place'){
          draft._parentSiteName = action.plot.name
        }
        draft.isPropertySelect = true
        break;
        } else {
          break;
        }
      }

      case 'App/PLOT_CREATE_ERROR': {
        draft.plotList = draft.plotList.filter(
          (plot) => plot.plotId !== action.tempPlotId
        );
        break;
      }

      case 'App/PLOT_FTECH_BY_TYPE': {
        if(!draft.user_type){
          if(action.response.userType === "homeOwner"){
            draft.user_type = 'home_user';
          } else if (action.response.userType === "eastateagent"){
            draft.user_type = 'eastate_agent'
          }
        }
        if(action.response.userType === "homeOwner" || action.response.userType === "eastateagent"){
          if(action.response.properties.length===0){
            break;
          }
          if(action.response.properties[0].property_type === "ROOM/space"){
            draft.selectedPlotType = "ROOM/space";
            draft._selecthomeId = action.response.properties[0].parent_propertyid;
          } else if(action.response.properties[0].property_type === "UNIT/home"){
            draft.selectedPlotType = "UNIT/home";
            draft._selectedbuildingId = action.response.properties[0].parent_propertyid;
          }
          else if(action.response.properties[0].property_type === "PLOT/building"){
            draft.selectedPlotType = "PLOT/building";
            draft._selectedPLOTID = action.response.properties[0].parent_propertyid;
          }
          else if(action.response.properties[0].property_type === "SITE/place"){
            draft.selectedPlotType = "SITE/place";
            draft._selectedbuildingId = action.response.properties[0].parent_propertyid;
          }
          if(action.response.properties.length === 0){
            draft.productList = [];
            draft.collaboratorList = [];
          }
          if(action.response.parentPropertyList === null){
            draft.parentPropertyList = undefined
          } else {
            draft.parentPropertyList = action.response.parentPropertyList;
          }

          const unsortedPlotList = action.response.properties.reduce(
            (plots, plot) => {
              plots.push({
                plotId: plot._id,
                plotName: plot.name,
                plotNumber: plot.number,
                plotImage: plot.image,
                uprn: plot.uprn,
                w3w: plot.w3w,
                deed: plot.deed,
                lrid: plot.lrid,
                productList: [],
                collaboratorList: [],
                selected: false,
              });
              return plots;
            },
            []
          );

          // TODO: ideally it's good if we could get the data pre-sorted from the server.
          const { preference } = action.response;
          let preffered_order = [];
          if (preference && preference.data) {
            preffered_order = preference.data.split(",");
          }

          if (preffered_order && unsortedPlotList) {
            draft.plotList = [...unsortedPlotList].sort(function (a, b) {
              return (
                preffered_order.indexOf(a.plotId) -
                preffered_order.indexOf(b.plotId)
              );
            });
            // draft.plotList = preffered_order.map((id)=> {unsortedPlotList.find(plot=>{return plot.plotId=== id;})});
          } else {
            draft.plotList = [...unsortedPlotList];
          }
          break;
        } else{
          if(action.response.properties.length===0){
            draft.productList = [];
            draft.collaboratorList = [];
          }
          if(action.response.parentPropertyList === null){
            draft.parentPropertyList = undefined
          } else {
            draft.parentPropertyList = action.response.parentPropertyList;
          }

          const unsortedPlotList = action.response.properties.reduce(
            (plots, plot) => {
              plots.push({
                plotId: plot._id,
                plotName: plot.name,
                plotNumber: plot.number,
                plotImage: plot.image,
                uprn: plot.uprn,
                w3w: plot.w3w,
                deed: plot.deed,
                lrid: plot.lrid,
                productList: [],
                collaboratorList: [],
                selected: false,
              });
              return plots;
            },
            []
          );
          // TODO: ideally it's good if we could get the data pre-sorted from the server.
          const { preference } = action.response;
          let preffered_order = [];
          if (preference && preference.data) {
            preffered_order = preference.data.split(",");
          }
  
          if (preffered_order && unsortedPlotList) {
            draft.plotList = [...unsortedPlotList].sort(function (a, b) {
              return (
                preffered_order.indexOf(a.plotId) -
                preffered_order.indexOf(b.plotId)
              );
            });
            // draft.plotList = preffered_order.map((id)=> {unsortedPlotList.find(plot=>{return plot.plotId=== id;})});
          } else {
            draft.plotList = [...unsortedPlotList];
          }
          break;
        }
        break;
      }

      case 'App/PLOT_FETCH_SUCCESS': {
        const array = action.response.properties
        draft.plotLoading = false
        draft.siteCount = array;
        break;
      }

      case 'App/CATEGORY_LOAD_SUCCESS': {
        const array = action.response.category
        draft.categories = array;
        break;
      }

      case 'App/PLOT_UPDATE_FIELDS_SUCCESS': {
        //draft.isPropertySelect = true;
        const update = {};
        update[action.field] = action.value;
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId ? { ...plot, ...update } : plot
        );
        break;
      }

      case 'App/PLOT_DELETE_SUBMIT': {
        draft.productList = [];
        draft.collaboratorList = [];
        draft.selectedPlotId = null;
        draft.selectedPlot = undefined;
        draft.productList = null;
        draft.isPropertySelect = false
        if(draft.selectedPlotType === 'SITE/place'){
          draft._selectedPLOTID = undefined;
          draft._parentSiteName = undefined;
          draft._parentPlotName = undefined
          draft._parentHomeName = undefined
        }if(draft.selectedPlotType === 'PLOT/building'){
          draft._selectedbuildingId = undefined;
          draft._parentPlotName = undefined
          draft._parentHomeName = undefined
        }if( draft.selectedPlotType === 'UNIT/home'){
          draft._selecthomeId = undefined;
          draft._parentHomeName = undefined
        }
        draft.plotList = draft.plotList.filter(
          (plot) => plot.plotId !== action.plotId
        );
        break;
      }

      case 'App/PRODUCT_ADD_MODAL_SHOW':
        draft.productAddModalShow = true;
        draft.selectedProductTab = 'product';
        break;

      case 'App/PRODUCT_ADD_MODAL_SHOW_WITH_CAT':{
        draft.productAddModalShow = true;
        draft.selectedCategory = action.cat;
        draft.displayCategoryView = false;
        draft.selectedProductTab = 'specs';
        break;
      }

      case 'App/PRODUCT_ADD_MODAL_HIDE':
        draft.productAddModalShow = false;
        draft.selectedCategory = undefined;
        break;

      case 'App/PRODUCT_EDIT_MODAL_SHOW':
        draft.productEditModalShow = true;
        break;

      case 'App/PRODUCT_EDIT_MODAL_HIDE':
        draft.productEditModalShow = false;
        break;

      case 'App/PRODUCT_SHARE_MODAL_SHOW':
        draft.productShareModalShow = true;
        break;

      case 'App/PRODUCT_SHARE_MODAL_HIDE':
        draft.productShareModalShow = false;
        break;

      case 'App/PLOT_SELECT': {
        draft.isPropertySelect = true
        draft.plotList = draft.plotList.map((plot) =>
          plot &&
          (plot.plotId === action.plotId )
            ? { ...plot, selected: true }
            : { ...plot, selected: false }
        );
        const selectedPlot = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if(action.isfirstplot){
          // 'parentplotId'
          if(action.parentplotId){
            draft._selectedbuildingId = action.parentplotId
          }
          if(action.parentId){
           draft._selectedPLOTID = action.parentId
          }
          if(action.homeId){
           draft._selecthomeId = action.homeId
          }
        }
        if (selectedPlot) {
          draft._selectedPropertyId = action.plotId;
          if(draft.selectedPlotType === 'SITE/place'){
            draft._selectedPLOTID = action.plotId;
            draft._parentSiteName = action.plotName;
            draft._selectedBiulding = draft.plotList.map((plot) =>
            plot &&
            (plot.plotId === action.plotId )
              ? { ...plot, selected: true }
              : { ...plot, selected: false }
          );
          }if(draft.selectedPlotType === 'PLOT/building'){
            draft._selectedbuildingId = action.plotId;
            draft._parentPlotName = action.plotName;
            draft._selectedPlot = draft.plotList.map((plot) =>
            plot &&
            (plot.plotId === action.plotId )
              ? { ...plot, selected: true }
              : { ...plot, selected: false }
          );
          }
          if(draft.selectedPlotType === 'UNIT/home'){
            draft._selecthomeId = action.plotId;
            draft._parentHomeName = action.plotName;
          }
          draft.productList = selectedPlot.productList.map((product, index) =>
            index === 0
              ? { ...product, selected: true }
              : { ...product, selected: false }
          );
          draft.selectedPlotId = selectedPlot.plotId;
          draft.selectedPlot = selectedPlot;
        } else {
          draft.productList = [];
          draft.selectedPlotId = null;
          draft.selectedPlot = null;
        }
        if (selectedPlot && draft.productList && draft.productList[0]) {
          draft.selectedProductId = draft.productList[0].productId;
          draft.selectedProduct = draft.productList[0];
        }
        draft.selectedCollaborator = null;
        break;
      }

      case 'App/PLOT_DETAILS_SHOW': {
        draft.plotList = draft.plotList.map((plot) =>
          plot &&
          (plot.plotId === action.plotId || plot.plotId === action.tempPlotId)
            ? { ...plot, showDetails: true }
            : { ...plot, showDetails: false }
        );
        break;
      }

      case 'App/PLOT_DETAILS_HIDE': {
        draft.plotList = draft.plotList.map((plot) =>
          plot &&
          (plot.plotId === action.plotId || plot.plotId === action.tempPlotId)
            ? { ...plot, showDetails: false }
            : { ...plot }
        );
        break;
      }

      case 'App/PLOT_UNSELECT':{
        draft.productList = [];
        draft.collaboratorList = [];
        if(draft.selectedPlotType === 'SITE/place'){
          draft._selectedPLOTID = undefined;
          draft._parentSiteName = undefined;
          draft._parentHomeName = undefined
          draft._parentPlotName = undefined
        }
        if(draft.selectedPlotType === 'PLOT/building'){
          draft._selectedbuildingId = undefined;
          draft._parentPlotName = undefined
          draft._parentHomeName = undefined
        }
        if( draft.selectedPlotType === 'UNIT/home'){
          draft._selecthomeId = undefined;
          draft._parentHomeName = undefined
        }
        draft.isPropertySelect = false
        draft.plotList = draft.plotList.map((plot) =>
          plot &&
          (plot.plotId === action.plotId )
            ? { ...plot, selected: true }
            : { ...plot, selected: false }
        );
        break;
      }

      case 'App/PLOT_UPDATE_SUBMIT': {
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                plotName: action.plotName,
                plotNumber: action.plotNumber,
                plotImage: action.plotImage ? action.plotImage.preview : plot.plotImage,
                isUpdating: true,
              }
            : plot
        );
        //TODO, is this required?
        if (draft.selectedPlotId === action.plotId) {
          draft.selectedPlot = draft.plotList.find(
            (plot) => plot.plotId === action.plotId
          );
        }
        break;
      }

      case 'App/PLOT_UPDATE_SUCCESS': {
        const updatedPlot = action.response ? action.response.plot : null;
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                plotName: updatedPlot ? updatedPlot.name : plot.plotName,
                plotNumber: updatedPlot ? updatedPlot.number : plot.plotNumber,
                plotImage: updatedPlot ? updatedPlot.image : null,
                isUpdating: false,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.selectedPlot = draft.plotList.find(
            (plot) => plot.plotId === action.plotId
          );
        }
        if(action.response.isParentUpdate){
          draft.plotList = draft.plotList.filter(
            (plot) => plot.plotId !== action.plotId
          );
          if (!draft.plotList || draft.plotList.length === 0) {
            draft.selectedPlotId = null;
            draft.selectedPlot = null;
            draft.productList = null;

            draft.productList = [];
            draft.collaboratorList = [];
            if(draft.selectedPlotType === 'SITE/place'){
              draft._selectedPLOTID = undefined;
            }
            if(draft.selectedPlotType === 'PLOT/building'){
              draft._selectedbuildingId = undefined;
            }
            if( draft.selectedPlotType === 'UNIT/home'){
              draft._selecthomeId = undefined;
            }
            draft.isPropertySelect = false
          }
        }
        break;
      }

      case 'App/PLOT_UPDATE_ERROR': {
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                isUpdating: false,
              }
            : plot
        );
        if (draft.selectedPlotId === action.plotId) {
          draft.selectedPlot = draft.plotList.find(
            (plot) => plot.plotId === action.plotId
          );
        }
        break;
      }

      case 'App/PLOT_REARRANGE_SUBMIT': {
        const plotsToReorder = draft.plotList.map((plot) => plot);
        draft.plotList = reorder(
          plotsToReorder,
          action.result.source.index,
          action.result.destination.index
        );
        break;
      }

      case 'APP/PLOT_VIEW_CHANGE':{
          draft.plotView =  action.view;
          break;
      }

      case 'App/PRODUCT_CREATE_SUBMIT': {

        draft.selectedCategory = undefined
        draft.selectedCategoryType = undefined

        const product = {
          productId: action.tempProductId,
          productName: action.productName,
          productNumber: action.productNumber,
          productDescription: action.productDescription,
          productImages: null,
          itemList: [], // TODO: add items according to the images
          isCreating: true, // set isCreating to true to show the loading animation
        };

        let selectedPlot = draft.plotList.find((plot) =>
          action.plotId
            ? plot.plotId === action.plotId
            : plot.plotId === action.tempPlotId
        );

        if (!selectedPlot || !draft.isPropertySelect) {

          if(draft.selectedPlotType === 'SITE/place'){
            selectedPlot = {
              plotId: action.tempPlotId,
              plotName: "Site-" + (draft.plotList.length+1),
              plotNumber: "" + (draft.plotList.length+1),
              plotImage: null,
              productList: [],
              collaboratorList: [],
              isCreating: true, // set isCreating to true to show the loading animation
              selected: true,
            };
            draft.selectedSitename = "Site-" + (draft.plotList.length+1)
          }

          if(draft.selectedPlotType === 'PLOT/building'){
            selectedPlot = {
              plotId: action.tempPlotId,
              plotName: "Plot-" + (draft.plotList.length+1),
              plotNumber: "" + (draft.plotList.length+1),
              plotImage: null,
              productList: [],
              collaboratorList: [],
              isCreating: true, // set isCreating to true to show the loading animation
              selected: true,
            };
          }

          if(draft.selectedPlotType === 'UNIT/home'){
            selectedPlot = {
              plotId: action.tempPlotId,
              plotName: "Unit-" + (draft.plotList.length+1),
              plotNumber: "" + (draft.plotList.length+1),
              plotImage: null,
              productList: [],
              collaboratorList: [],
              isCreating: true, // set isCreating to true to show the loading animation
              selected: true,
            };
          }

          if(draft.selectedPlotType === 'ROOM/space'){
            selectedPlot = {
              plotId: action.tempPlotId,
              plotName: "Room-" + (draft.plotList.length+1),
              plotNumber: "" + (draft.plotList.length+1),
              plotImage: null,
              productList: [],
              collaboratorList: [],
              isCreating: true, // set isCreating to true to show the loading animation
              selected: true,
            };
          }
          draft.plotList.unshift(selectedPlot);
        }
        draft.selectedPlotId = selectedPlot.plotId;
        draft.selectedPlot = selectedPlot;
        draft.productList = selectedPlot.productList;
        draft.productList.unshift(product);
        draft.isPropertySelect = true;
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId || plot.plotId === action.tempPlotId
            ? {
                ...plot,
                productList: draft.productList,
              }
            : plot
        );
        draft.selectedProductId = action.tempProductId;
        draft.selectedProduct = product;
        break;
      }

      case 'App/PRODUCT_CREATE_SUCESS': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plot._id || plot.plotId === action.tempPlotId
        );
        
        if(action.response.isFirst){

          if(draft.selectedPlotType === 'SITE/place'){
            draft._selectedPLOTID = action.response.plot._id
            draft._parentSiteName = action.response.plot.name;
          }

          if(draft.selectedPlotType === 'PLOT/building'){
            draft._selectedPLOTID = action.response.parent_site
            draft._selectedbuildingId = action.response.plot._id
            draft._parentPlotName = action.response.plot.name;
          }

          if( draft.selectedPlotType === 'UNIT/home'){
            draft._selectedPLOTID = action.response.parent_site
            draft._selectedbuildingId = action.response.parent_plot
            draft._selecthomeId = action.response.plot._id
            draft._parentHomeName = action.response.plot.name;
          }

          if( draft.selectedPlotType === 'ROOM/space'){
            draft._selectedPLOTID = action.response.parent_site
            draft._selectedbuildingId = action.response.parent_plot
            draft._selecthomeId = action.response.parent_home
            draft._selectedRoomId = action.response.plot._id
          }

        }
        
        if (!plotToUpdate) {
          break;
        }

        const updatedProduct = {
          isCreating: false,
          productId: action.product._id,
          itemList: action.items
                    ? action.items.reduce((items, item, index) => {
                        items.push({
                          productItemId: item._id,
                          productItemTitle: item.title,
                          productItemDescription: item.description,
                          productItemGeolocation: item.geolocation,
                          productItemImage: item.file,
                          selected: index === 0,
                        });
                        return items;
                      }, [])
                    : [],
          product_specs: action.response && action.response.specs ?
                          action.response.specs.reduce((speces, specs, index) => {
                            speces.push({
                              productspecsId: specs._id,
                              productspecsTitle: specs.specsName,
                              productspecsvalue: specs.assignValue,
                            });
                            return speces;
                          }, [])
                          : [],
          category: action.product.product_category_id && action.response.__category ? action.response.__category.find(x => x._id === action.product.product_category_id) : undefined
        };

        const productList = plotToUpdate.productList.map((product) =>
          product.productId === action.product._id ||
          product.productId === action.tempProductId
            ? {...product,...updatedProduct} : product
        );

        if(action.tempProductId === draft.selectedProductId || (draft.selectedProduct && draft.selectedProduct.productId === action.tempProductId )){
            draft.selectedProduct = {...draft.selectedProduct, ...updatedProduct};
            draft.selectedProductId =  action.product._id;
        }

        if (plotToUpdate.plotId === draft.selectedPlotId) {
          draft.productList = productList;
          draft.selectedPlotId = action.plot._id;
          draft.selectedPlot = plotToUpdate;
        }

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plot._id || plot.plotId === action.tempPlotId
            ? {
                ...plot,
                productList,
                isCreating: false,
                plotId: action.plot._id,
              }
            : plot
        );

        draft.isPropertySelect = true;
        break;
      }

      case 'App/PRODUCT_CREATE_ERROR': {
        if (action.tempPlotId) {
          draft.plotList = draft.plotList.filter(
            (plot) => plot.plotId !== action.tempPlotId
          );
          if (draft.selectedPlotId === action.tempPlotId) {
            draft.selectedPlotId = null;
            draft.selectedPlot = null;
            draft.productList = [];
          }
        } else if (action.plotId) {
          const plotToRevert = draft.plotList.find(
            (plot) => plot.plotId === action.plotId
          );
          let revertedProductList = [];
          if (plotToRevert) {
            revertedProductList = plotToRevert.productList.filter(
              (product) => product.productId !== action.tempProductId
            );
          }
          draft.plotList.map((plot) =>
            plot.plotId === action.plotId
              ? { ...plot, productList: revertedProductList }
              : plot
          );
          if (draft.selectedPlotId === action.plotId) {
            draft.productList = revertedProductList;
          }
        }
        break;
      }

      case 'App/PRODUCT_FETCH_SUBMIT':
        break;

      case 'App/PRODUCT_FETCH_SUCCESS': {
        
        const productList = action.response.products.reduce(
          (products, product, productIndex) => {
            products.push({
              productId: product._id,
              productName: product.name,
              productDescription: product.description,
              selected: productIndex === 0,
              itemList: product.items
                ? product.items.reduce((items, item, index) => {
                    items.push({
                      productItemId: item._id,
                      productItemTitle: item.title,
                      productItemDescription: item.description,
                      productItemGeolocation: item.geolocation,
                      productItemImage: item.file,
                      selected: index === 0,
                    });
                    return items;
                  }, [])
                : [],
                product_specs:product.product_specs ?
                product.product_specs.reduce((speces, specs, index) => {
                  speces.push({
                    productspecsId: specs._id,
                    productspecsTitle: specs.specsName,
                    productspecsvalue: specs.assignValue,
                  });
                  return speces;
                }, [])
                : [],
               category:product.product_category_id && action.response.__category ? action.response.__category.find(x => x._id === product.product_category_id): undefined
           
            });
            return products;
          },
          []
        );

        const { preference } = action.response;

        let preffered_order = [];

        if (preference && preference.data) {
          preffered_order = preference.data.split(",");
        }

        let productListToAttach = [];

        if (preffered_order && productList) {
          productListToAttach = [...productList].sort(function (a, b) {
            return (
              preffered_order.indexOf(a.productId) -
              preffered_order.indexOf(b.productId)
            );
          });
        } else {
          productListToAttach = [...productList];
        }

        const sortedProductListToAttach = productListToAttach.map(
          (product, index) =>
            index === 0
              ? { ...product, selected: true }
              : { ...product, selected: false }
        );

        if (action.plotId === draft.selectedPlotId) {
          draft.productList = sortedProductListToAttach;
          if (draft.productList && draft.productList[0]) {
            draft.selectedProductId = draft.productList[0].productId;
            draft.selectedProduct = draft.productList[0];
          }
        }

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? { ...plot, productList: sortedProductListToAttach }
            : plot
        );
        break;
      }

      case 'App/PRODUCT_FETCH_ERROR':
        break;

      case 'App/PRODUCT_DELETE_SUBMIT': {
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: plot.productList.filter(
                  (product) => product.productId !== action.productId
                ),
              }
            : plot
        );

        if (action.plotId === draft.selectedPlotId) {
          draft.productList = draft.productList.filter(
            (product) => product.productId !== action.productId
          );
        }

        break;
      }

      case 'App/PLOT_TYPE_CHANGE': {
        if(action.direction === 'up'){
          if(draft.selectedPlotType === 'ROOM/space'){
            draft._selectedRoomId = undefined
          }
          if(draft.selectedPlotType === 'UNIT/home'){
            draft._selecthomeId = undefined
          }
          if(draft.selectedPlotType === 'PLOT/building'){
            draft._selectedbuildingId = undefined
          }
        }
        draft.selectedPlotType=draft.propertyType[action.status]
        draft.isPropertySelect = false;
        break;
      }

      case 'App/PRODUCT_SELECT': {
        
        if (!action.productId) {
          break;
        }
        const selectedProduct = draft.productList.find(
          (product) => product && product.productId === action.productId
        );
        if (!selectedProduct){break;}
        selectedProduct.selected = true;

        draft.productList = draft.productList.map((product) =>
          product && product.productId === action.productId
            ? { ...selectedProduct }
            : { ...product, selected: false }
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.selected ? { ...plot, productList: draft.productList } : plot
        );

        draft.selectedProductId = action.productId;
        draft.selectedProduct = selectedProduct;

        break;
      }

      case 'App/PRODUCT_REARRANGE_SUBMIT': {

        const productsToRearrange = draft.productList.map((p) => p);

        draft.productList = reorder(
          productsToRearrange,
          action.result.source.index,
          action.result.destination.index
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? { ...plot, productList: draft.productList }
            : plot
        );

        break;
      }

      case 'App/PRODUCT_UPDATE_SUCESS': {

        const resProduct = action.response.product;

        if (!resProduct) {
          break;
        }

        var update;

        update = {
          category: draft.categories.find(x => x._id === action.response.product.product_category_id),
          productName: resProduct.name,
          productDescription: resProduct.description,
          product_specs: action.response.specs ?
                          action.response.specs.reduce((speces, specs, index) => {
                            speces.push({
                              productspecsId: specs._id,
                              productspecsTitle: specs.specsName,
                              productspecsvalue: specs.assignValue,
                            });
                            return speces;
                          }, [])
                          : [],
        };

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === resProduct.property_id
            ? {
                ...plot,
                productList: plot.productList.map((product) =>
                  product.productId === action.productId
                    ? { ...product, ...update }
                    : product
                ),
              }
            : plot
        );

        if (draft.selectedPlotId === resProduct.property_id) {
          draft.productList = draft.productList.map((product) =>
            product.productId === action.productId
              ? { ...product, ...update }
              : product
          );
        }

        if ( draft.selectedProduct && draft.selectedProduct.productId === action.productId ) {
          draft.selectedProduct = { ...draft.selectedProduct, ...update };
        }
        break;
      }

      case 'App/PRODUCT_UPDATE_FIELDS_SUCCESS': {

        const update = {};

        update[action.field] = action.value;

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: plot.productList.map((product) =>
                  product.productId === action.productId
                    ? { ...product, ...update }
                    : product
                ),
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = draft.productList.map((product) =>
            product.productId === action.productId
              ? { ...product, ...update }
              : product
          );
        }

        if (draft.selectedProduct && draft.selectedProduct.productId === action.productId) {
          draft.selectedProduct = { ...draft.selectedProduct, ...update };
        }
        break;
      }

      case 'App/PRODUCT_ITEM_ADD_MODAL_SHOW': {
        draft.showProductItemAddModal = action.show;
        break;
      }

      case 'App/PRODUCT_ITEM_ADD_MODAL_HIDE': {
        draft.showProductItemAddModal = !action.hide;
        break;
      }

      case 'App/PRODUCT_ITEM_EDIT_MODAL_SHOW': {
        draft.showProductItemEditModal = action.show;
        draft.selectedItemIndex = action.selectedIndex;
        draft.selectedProduct = action.product;
        draft.selectedItem = action.item;
        break;
      }

      case 'App/PRODUCT_ITEM_EDIT_MODAL_HIDE': {
        draft.showProductItemEditModal = !action.hide;
        break;
      }

      case 'App/PRODUCT_ITEM_CREATE_SUBMIT': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: appendItemList(action, product.itemList),
                isUpdating: true,
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }
        break;
      }

      case 'App/PRODUCT_ITEM_CREATE_SUCCESS': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.map((item, index, arr) =>
                  item.productItemId === action.tempProductItemId
                    ? {
                        productItemId: action.response.item._id,
                        productItemImage: action.response.item.file,
                        isCreating: false,
                        productItemTitle: action.response.item.title,
                        productItemDescription: action.response.item.description,
                        productItemGeolocation: action.response.item.geolocation,
                        selected: index === 0,
                      }
                    : item
                ),
                isUpdating: false,
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_CREATE_ERROR': {
        break;
      }

      case 'App/PRODUCT_ITEM_DELETE_SUBMIT': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.filter(
                  (item) => item.productItemId !== action.productItemId
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_FULL_VIEW_MODAL_SHOW': {
        draft.showProductItemFullViewModal = action.show;
        draft.selectedItemIndex = action.selectedIndex;
        draft.selectedProduct = action.product;
        draft.selectedItem = action.item;
        break;
      }

      case 'App/PRODUCT_ITEM_FULL_VIEW_MODAL_HIDE': {
        draft.selectedItemIndex = undefined;
        draft.selectedProduct = undefined;
        draft.selectedItem = undefined;
        draft.showProductItemFullViewModal = !action.hide;
        break;
      }

      case 'App/PRODUCT_ITEM_SELECT': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.map((productItem) =>
                  productItem.productItemId === action.productItemId
                    ? { ...productItem, selected: true }
                    : { ...productItem, selected: false }
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_UPDATE_SUBMIT': {

        const itemToUpdate = {
          productItemId: action.productItemId,
          productItemTitle: action.productItemTitle,
          productItemDescription: action.productItemDescription,
          productItemGeolocation: action.productItemGeolocation,
          productItemImagePreview: action.productItemImage
            ? action.productItemImage.preview
            : null,
          isUpdating: true,
        };

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
      
            ? {
                ...product,
                itemList: product.itemList.map((productItem) =>
                  productItem.productItemId === action.productItemId
                    ? { ...productItem, ...itemToUpdate }
                    : productItem
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }
        if (
          draft.selectedItem &&
          draft.selectedItem.productItemId === action.productItemId
        ) {
          draft.selectedItem = itemToUpdate;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_UPDATE_SUCCESS': {

        const itemToUpdate = {
          productItemId: action.response.productItem._id,
          productItemTitle: action.response.productItem.title,
          productItemDescription: action.response.productItem.description,
          productItemGeolocation: action.response.productItem.geolocation,
          productItemImage: action.response.productItem.file,
          productItemImagePreview: null,
          isUpdating: false,
        };

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.map((productItem) =>
                  productItem.productItemId === action.productItemId
                    ? { ...productItem, ...itemToUpdate }
                    : productItem
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }
        if (
          draft.selectedItem &&
          draft.selectedItem.productItemId === action.productItemId
        ) {
          draft.selectedItem = itemToUpdate;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_UPDATE_ERROR': {
        const itemToUpdate = {
          productItemImagePreview: null,
          isUpdating: false,
        };

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if (!plotToUpdate) {
          break;
        }

        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.map((productItem) =>
                  productItem.productItemId === action.productItemId
                    ? { ...productItem, ...itemToUpdate }
                    : productItem
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }
        if (
          draft.selectedItem &&
          draft.selectedItem.productItemId === action.productItemId
        ) {
          draft.selectedItem = itemToUpdate;
        }

        break;
      }

      case 'App/PRODUCT_ITEM_FIELDS_UPDATE_SUBMIT': {
        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if (!plotToUpdate) {
          break;
        }
        const update = {};
        update[action.field] = action.value;
        const updatedProducts = plotToUpdate.productList.map((product) =>
          product.productId === action.productId
            ? {
                ...product,
                itemList: product.itemList.map((productItem) =>
                  productItem.productItemId === action.productItemId
                    ? { ...productItem, ...update }
                    : productItem
                ),
              }
            : product
        );

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                productList: updatedProducts,
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.productList = updatedProducts;
        }

        break;
      }

      case 'App/ESTATE_AGENCY_SUGGESTIONS_FETCH_SUCCESS': {
        draft.estateAgencySuggestionsSearchResults = action.response.estateAgencies.map(
          (ea) => ({
            name: ea.name,
            logo: ea.firm_logo,
            estateAgencyId: ea._id,
          })
        );
        break;
      }

      case 'App/ESTATE_AGENCY_SUGGESTIONS_FETCH_ERROR': {
        draft.estateAgencySuggestionsSearchResults = [];
        break;
      }

      case 'App/ESTATE_AGENCY_SUGGESTIONS_RESET': {
        draft.estateAgencySuggestionsSearchResults = [];
        break;
      }

      case 'App/COLLABORATOR_INVITE_MODAL_SHOW':
        draft.collaboratorInviteModalShow = true;
        break;

      case 'App/COLLABORATOR_INVITE_MODAL_HIDE':
        draft.collaboratorInviteModalShow = false;
        break;

      case 'App/COLLABORATOR_SUGGESTIONS_FETCH_ERROR': {
        draft.collaboratorSuggestionsSearchResults = [];
        break;
      }

      case 'App/COLLABORATOR_SUGGESTIONS_RESET': {
        draft.collaboratorSuggestionsSearchResults = [];
        break;
      }

      case 'App/COLLABORATOR_INVITE_SUBMIT': {
        // if no plot id is given, break;
        const selectedPlot = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
        if (!selectedPlot) {
          break;
        }
        const collaborator = {
          collaboratorId: action.tempCollaboratorId,
          collaboratorName: action.name,
          //          collaboratorEmail: action.collaboratorEmail,
          collaboratorRole: action.role,
          collaboratorInvitationStatus: "invited",
          isCreating: true, // set isCreating to true to show the loading animation
        };
        draft.selectedPlotId = selectedPlot.plotId;
        draft.selectedPlot = selectedPlot.plotId;
        const collaboratorListToUpdate = selectedPlot.collaboratorList;
        collaboratorListToUpdate.unshift(collaborator);
        draft.collaboratorList = collaboratorListToUpdate;

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList: collaboratorListToUpdate,
              }
            : plot
        );
        draft.selectedCollaboratorId = action.tempCollaboratorId;
        break;
      }

      case 'App/COLLABORATOR_INVITE_SUCCESS': {
        console.log(action)
        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          // TODO: unlikely to happen, but if it happens, best is to reload the page as a fresh start.
          break;
        }
        const collaboratorList = plotToUpdate.collaboratorList.map(
          (collaborator) =>
            collaborator.collaboratorId === action.collaborator._id ||
            collaborator.collaboratorId === action.tempCollaboratorId
              ? {
                  ...collaborator,
                  isCreating: false,
                  initials:action.initials,
                  collaboratorId: action.collaborator._id,
                  collaboratorInviteeEmailMd5:
                    action.collaborator.invitee_email_md5,
                }
              : collaborator
        );

        if (plotToUpdate.plotId === draft.selectedPlotId) {
          draft.collaboratorList = collaboratorList;
          draft.selectedPlotId = action.plotId;
          draft.selectedPlot = plotToUpdate;
          if (collaboratorList[0]) {
            draft.selectedCollaboratorId = action.collaborator._id;
          }
        }
        break;
      }

      case 'App/COLLABORATOR_INVITE_ERROR': {

        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );

        if (!plotToUpdate) {
          break;
        }

        const collaboratorList = plotToUpdate.collaboratorList.filter(
          (collaborator) =>
            collaborator.collaboratorId !== action.tempCollaboratorId
        );

        if (plotToUpdate.plotId === draft.selectedPlotId) {
          draft.collaboratorList = collaboratorList;
          draft.selectedCollaboratorId = collaboratorList[0]
            ? collaboratorList[0].collaboratorId
            : null;
        }

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList,
              }
            : plot
        );

        break;
      }

      case 'App/COLLABORATOR_FETCH_SUBMIT':
        break;

      case 'App/COLLABORATOR_FETCH_SUCCESS': {
        draft.collaboratorList = action.response.collaborators.map(function(collaborator){
          return {
              mikuType: "DTxCollaborator2",
              collaboratorId: collaborator._id,
              collaboratorName: collaborator.name,
              showDetails: false
          }
        });
        break;
      }

      case 'App/COLLABORATOR_FETCH_ERROR':
        break;

      case 'App/COLLABORATOR_REMOVE_SUCCESS': {
        const plotToUpdate = draft.plotList.find(
          (plot) => plot.plotId === action.plotId
        );
       
        if (!plotToUpdate) {
          // TODO: unlikely to happen, but if it happens, best is to reload the page as a fresh start.
          break;
        }

        const collaboratorList = plotToUpdate.collaboratorList.filter(
          (collaborator) =>
            collaborator.collaboratorId !== action.collaboratorId
        );
        const invitedList = plotToUpdate.invitedList.filter(
          (collaborator) =>
            collaborator.collaboratorInviteeEmailMd5 !== action.inviteeEmailMd5
        );
        const acceptedList = plotToUpdate.acceptedList.filter(
          (collaborator) =>
            collaborator.collaboratorInviteeEmailMd5 !== action.inviteeEmailMd5
        );

        if (plotToUpdate.plotId === draft.selectedPlotId) {
          draft.collaboratorList = collaboratorList;

          draft.selectedCollaboratorId = collaboratorList[0]
            ? collaboratorList[0].collaboratorId
            : null;
        }

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList,
                invitedList,
                acceptedList,
              }
            : plot
        );

        break;
      }

      case 'App/COLLABORATOR_SELECT': {
        const selectedCollaborator = draft.collaboratorList.find(
          (collaborator) =>
            collaborator.collaboratorId === action.collaboratorId
        );

        draft.selectedCollaborator = selectedCollaborator || null;

        draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
          collaborator && collaborator.collaboratorId === action.collaboratorId
            ? { ...collaborator, selected: true }
            : { ...collaborator, selected: false }
        );

        break;
      }

      case 'App/COLLABORATOR_REARRANGE_SUBMIT': {
        const collaboratorsToReorder = draft.collaboratorList.map(
          (plot) => plot
        );
        draft.collaboratorList = reorder(
          collaboratorsToReorder,
          action.result.source.index,
          action.result.destination.index
        );

        break;
      }

      case 'App/COLLABORATOR_EDIT_MODAL_SHOW':
        draft.collaboratorEditModalShow = true;
        break;

      case 'App/COLLABORATOR_EDIT_MODAL_HIDE':
        draft.collaboratorEditModalShow = false;
        break;

      case 'App/COLLABORATOR_UPDATE_FIELDS_SUCCESS': {
        const update = {};
        update[action.field] = action.value;
        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList: plot.collaboratorList.map((collaborator) =>
                  collaborator.collaboratorId === action.collaboratorId
                    ? { ...collaborator, ...update }
                    : collaborator
                ),
              }
            : plot
        );
        if (draft.selectedPlotId === action.plotId) {
          draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
            collaborator.collaboratorId === action.collaboratorId
              ? { ...collaborator, ...update }
              : collaborator
          );
        }
        if (
          draft.selectedCollaborator &&
          draft.selectedCollaborator.collaboratorId === action.collaboratorId
        ) {
          draft.selectedCollaborator = {
            ...draft.selectedCollaborator,
            ...update,
          };
        }
        break;
      }

      case 'App/COLLABORATOR_DETAILS_SHOW': {
        draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
          collaborator &&
          (collaborator.collaboratorId === action.collaboratorId ||
            collaborator.collaboratorId === action.tempPlotId)
            ? { ...collaborator, showDetails: true }
            : { ...collaborator, showDetails: false }
        );
        break;
      }

      case 'App/COLLABORATOR_DETAILS_HIDE': {
        draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
          collaborator &&
          (collaborator.collaboratorId === action.collaboratorId ||
            collaborator.collaboratorId === action.tempPlotId)
            ? { ...collaborator, showDetails: false }
            : { ...collaborator }
        );
        break;
      }

      case 'App/COLLABORATOR_UPDATE_SUBMIT': {
        const update = {};
        if (action.name) {
          update["collaboratorName"] = action.name;
        }
        if (action.role) {
          update["collaboratorRole"] = action.role;
        }

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList: plot.collaboratorList.map((collaborator) =>
                  collaborator.collaboratorId === action.collaboratorId
                    ? { ...collaborator, ...update }
                    : collaborator
                ),
              }
            : plot
        );
        if (draft.selectedPlotId === action.plotId) {
          draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
            collaborator.collaboratorId === action.collaboratorId
              ? { ...collaborator, ...update }
              : collaborator
          );
        }

        if (
          draft.selectedCollaborator &&
          draft.selectedCollaborator.collaboratorId === action.collaboratorId
        ) {
          draft.selectedCollaborator.collaboratorName =
            action.name || draft.selectedCollaborator.collaboratorName;
          draft.selectedCollaborator.collaboratorRole =
            action.role || draft.selectedCollaborator.collaboratorRole;
        }
        break;
      }

      case 'App/COLLABORATOR_UPDATE_SUCCESS': {
        const rc = action.response ? action.response.collaborator : null;
        const ru =
          ru && action.response ? action.response.collaborator.user : null;
        const update = rc
          ? {
              collaboratorName: ru ? ru.name : rc.invitee_name,
              collaboratorRole: rc.role,
              collaboratorInviteeEmailMd5: ru
                ? ru.emailMd5
                : rc.invitee_emailMd5,
              isUpdating: false,
              initials: rc.profile.initials,
            }
          : { isUpdating: false };

        draft.plotList = draft.plotList.map((plot) =>
          plot.plotId === action.plotId
            ? {
                ...plot,
                collaboratorList: plot.collaboratorList.map((collaborator) =>
                  collaborator.collaboratorId === action.collaboratorId
                    ? { ...collaborator, ...update }
                    : collaborator
                ),
              }
            : plot
        );

        if (draft.selectedPlotId === action.plotId) {
          draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
            collaborator.collaboratorId === action.collaboratorId
              ? { ...collaborator, ...update }
              : collaborator
          );
        }

        if (draft.selectedCollaborator && draft.selectedCollaborator.collaboratorId === action.collaboratorId) {
          draft.selectedCollaborator = {
            ...draft.selectedCollaborator,
            ...update,
          };
        }
        break;
      }

      case 'App/COLLABORATOR_UPDATE_ERROR': {
        draft.collaboratorList = draft.collaboratorList.map((collaborator) =>
          collaborator.collaboratorId === action.collaboratorId
            ? {
                ...collaborator,
                isUpdating: false,
              }
            : collaborator
        );
        if (draft.selectedPlotId === action.collaboratorId) {
          draft.selectedPlot = draft.collaboratorList.find(
            (collaborator) =>
              collaborator.collaboratorId === action.collaboratorId
          );
        }
        break;
      }
      
      case 'APP/COLLABORATOR_VIEW_CHANGE': {
          draft.collaboratorView =  action.view;
          break;
      }

      case 'App/ACTIVATION_FETCH_SUCCESS': {
        const { user, collaborator, firm } = action.response;
        draft.signupModalShow = !user;
        draft.loginModalShow = !!user;
        draft.authprefill.email = collaborator.invitee_email;
        draft.authprefill.company = firm.name;
        draft.authprefill.name = user ? user.name : collaborator.invitee_name;
        draft.authprefill.role = collaborator.role;
        draft.authprefill.invitation_code = collaborator.invitation_code;
        draft.authprefill.firm_id = firm._id;
        break;
      }

      case 'App/SHARING_VIEW_SUCCESS': {
        const { plot, product } = action.response;
        draft.productList = [
          {
            productId: product._id,
            productName: product.name,
            productDescription: product.description,
            selected: true,
            itemList: product.items
              ? product.items.reduce((items, item, index) => {
                  items.unshift({
                    productItemId: item._id,
                    productItemTitle: item.title,
                    productItemDescription: item.description,
                    productItemGeolocation: item.geolocation,
                    productItemImage: item.file,
                    selected: index === product.items.length - 1,
                  });
                  return items;
                }, [])
              : [],
          },
        ];

        draft.plotList = [
          {
            plotId: plot._id,
            plotName: plot.name,
            plotNumber: plot.number,
            plotImage: plot.image,
            uprn: plot.uprn,
            w3w: plot.w3w,
            deed: plot.deed,
            lrid: plot.lrid,
            productList: draft.productList,
            collaboratorList: [],
            selected: true,
          },
        ];
        draft.selectedPlotId =  plot._id;
        break;
      }

      case 'App/DASHBOARD_VIEW_CHANGE':{
          draft.dashboardView =  action.view;
          break;
      }
    }
  });

const appendItemList = (action, itemList) => [
    ...itemList,
    {
      productItemId: action.tempProductItemId,
      productItemTitle: action.productItemTitle
        ? action.productItemTitle
        : action.productItemImage
        ? action.productItemImage.name
        : `Item-${itemList.length + 1}`,
      productItemDescription: action.productItemDescription
        ? action.productItemDescription
        : action.productItemImage
        ? action.productItemImage.name
        : `Item-${itemList.length + 1}`,
      selected: true,
      isCreating: true,
    },
];

const reorder = (list, start, end) => {
  const copy = [...list];
  const item = copy[start];
  const a1 = copy.filter(
    (x, index) => index !== start && (start > end ? index < end : index <= end)
  );
  const a2 = copy.filter(
    (x, index) => index !== start && (start > end ? index >= end : index > end)
  );
  return [...a1, item, ...a2];
};

export default appReducer;
