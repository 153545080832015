import { createGlobalStyle } from "styled-components";

import multifunction_icon from "images/button-multifunction-triangle.png";
import multifunction_vertical_icon from "images/button-multifunction-vline.png";
import multifunction_horizontal_icon from "images/button-multifunction-hline.png";
import button_remove_icon from "images/button-remove.png";
import button_remove_large_icon from "images/button-remove-large.png";
import product_icon from "images/product-icon.png";
import solid_right_product from "images/left-solid-product.png";
import solid_left_product from "images/right-solid-product.png";
import specs_icon from "images/specs-icon.png";
import item_icon from "images/item-icon.png";
import market_icon from "images/maket-icon.png";
import date_product_icon from "images/date-icon.png";
import button_return_icon from "images/button-return.png";
import button_remove_hover_icon from "images/button-remove-hover.png";
import button_remove_large_hover_icon from "images/button-remove-large-hover.png";
import create_property_icon from "images/create-property.png";
import create_property_icon_plot from "images/create-property-plot.png";
import create_property_icon_site from "images/create-property-site.png";
import create_property_icon_room from "images/create-property-room.png";
import uparrow from "images/uparrow.png";
import downarrow from "images/downarrow.png";
import uparrowdisabled from "images/uparrow-disabled.png";
import downarrowdisabled from "images/downarrow-disabled.png";
import property_background_icon from "images/property-background.png";
import properties_full_to_limited_view_icon from "images/prop-full-to-limited.png";
import properties_full_to_minimized_view_icon from "images/prop-full-to-minimized.png";
import properties_limited_view_wrapper_icon from "images/prop-limited-view-wrapper.png";
import properties_minimized_to_limited_view_icon from "images/prop-minimized-to-limited.png";
import properties_minimized_to_full_view_icon from "images/prop-minimized-to-full.png";
import user_full_to_limited_view_icon from "images/user-full-to-limited.png";
import user_full_to_minimized_view_icon from "images/user-full-to-minimized.png";
import user_limited_view_wrapper_icon from "images/user-limited-view-wrapper.png";
import user_minimized_to_limited_view_icon from "images/user-minimized-to-limited.png";
import user_minimized_to_full_view_icon from "images/user-minimized-to-full.png";

import property_tile_hexagon_selected_blue_background from "images/property-tile-hexagon-selected.png";
import property_tile_triangle_background from "images/property-tile-triangle.png";
import property_tile_squer_background from "images/property-tile-hexagon.png";
import property_tile_triangle_selected_blue_background from "images/property-tile-triangle-selected-blue.png";
import property_tile_triangle_selected_purple_background from "images/property-tile-triangle-detail-purple.png";
import property_add_modal_title_icon from "images/modal-property-add.png";
import plot_tile_notification_counter_placeholder from "images/plot-tile-notification-count.png";
import user_tile_notification_counter_placeholder from "images/user-tile-notification-count.png";
import product_add_modal_title_icon from "images/modal-product-add.png";
import product_item_add_modal_title_icon from "images/modal-product-item-add.png";
import user_add_modal_title_icon from "images/modal-user-add.png";
import modal_label_title from "images/modal-label-title.png";
import modal_label_image from "images/modal-label-image.png";
import modal_label_email from "images/modal-label-email.png";
import modal_label_key from "images/modal-label-key.png";
import modal_label_company from "images/modal-label-company.png";
import modal_label_house from "images/modal-label-house.png";
import modal_label_landmark from "images/modal-label-landmark.png";
import modal_label_indent from "images/modal-label-indent.png";
import modal_label_link from "images/modal-label-link.png";
import modal_label_share from "images/modal-label-share.png";
import modal_label_building from "images/modal-label-building.png";
import modal_button_small_close from "images/modal-small-close.png";
import modal_button_small_close_hover from "images/modal-small-close-hover.png";
import modal_button_close from "images/modal-medium-close.png";
import modal_button_close_hover from "images/modal-medium-close-hover.png";
import modal_label_description from "images/modal-label-description.png";
import modal_property_add_corner_background from "images/modal-property-add-corner-top.png";

import settings_icon from "images/button-settings.png";
import uprn_icon from "images/uprn.png";
import property_type_icon from "images/building.png";
import w3w_icon from "images/building.png";
import deed_icon from "images/building.png";
import notes_icon from "images/building.png";
import safety_icon from "images/building.png";
import lrid_icon from "images/building.png";
import date_icon from "images/building.png";
import initials_title_icon from "images/building.png";
import email_title_icon from "images/building.png";
import mobile_icon from "images/building.png";
import twitter_icon from "images/building.png";
import facebook_icon from "images/building.png";
import linkedin_icon from "images/building.png";
import nproperties_icon from "images/building.png";
import level_icon from "images/building.png";
import company_logo from "images/company-logo-dev.png";
import add_product_drag_drop from "images/add-product-drag-drop.png";
import add_product_compliance from "images/add-product-compliance.png";
import add_product_compliance_selected from "images/compliance-selected.png";
import add_product_legal from "images/add-product-legal.png";
import add_product_legal_selected from "images/legal-selected.png";
import add_product_module from "images/add-product-module.png";
import add_product_module_selected from "images/module-selected.png";
import product_marketplace from "images/product-marketplace.png";
import invite_user_icon from "images/invite-user-icon.png";
import user_background from "images/user-background.png";
import edit_menu_link from "images/edit-menu-link.png";
import product_tile_add_item from "images/add-item-drag-drop.png";
import product_tile_item_navigate_previous from "images/item-navigate-previous.png";
import product_tile_item_navigate_previous_large from "images/item-navigate-previous-large.png";
import product_tile_item_navigate_previous_large_hover from "images/item-navigate-previous-large-hover.png";
import product_tile_item_navigate_next from "images/item-navigate-next.png";
import product_tile_item_navigate_next_large from "images/item-navigate-next-large.png";
import product_tile_item_navigate_next_large_hover from "images/item-navigate-next-large-hover.png";
import home_background from "images/home-background.jpg";
import company_logo_long from "images/company-logo-long.png";

const GlobalStyle = createGlobalStyle`

html, body {
    background-color: #E5E5E5;
    height: 97%;
    min-height: 97%;
}

:focus {
    outline: none;
}

#app {
    height: 100%;
    min-height: 100%;
}
.page-container {
    min-width: 1194px;
    height: 100%;
    min-height: 100%;
    width: 1194px;
}
.homepage {
    min-width: 1194px !important;
    width: 100% !important;
}
.homepage.page-container {
    height: auto;
}
.single-page-row {
    height: 100%;
    min-height: 100%;
}
.createhere {
    height: 22px;
}
.property-name-selected-view {
    margin-top: 3px;
    border: 1px solid #738C97;
    border-radius: 4px;
    width: 178px;
    text-align: center;
    background-color: #738C97 ;
    height: 24px;
}
.plot-name {
    background-color: #A1B1B9 !important;
    border: 1px solid #A1B1B9 !important;
    color: #434552 !important;
}
.site-name {
    background-color: #CCD5D9 !important;
    border: 1px solid #CCD5D9 !important;
    color: #434552 !important;
}
.home-name {
    background-color: #738C97 !important;
    border: 1px solid #738C97 !important;
    color: #434552 !important;
}
.property-name-not-selected-view {
    margin-top: 3px;
    border-radius: 4px;
    width: 178px;
    text-align: center;
    border: 1px solid black;
    height: 24px;
}
.property-name-selected-view span {
    font-size: 16px;
}
.property-name-not-selected-view span {
    font-size: 16px;
}

/* Common */

.column-header {
    height: 68px;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
}
.column-header h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.action-bar {
    height: 68px;
    cursor: pointer;
}
.property-type-bar-next {
    background: url(${
        uparrow
    }) no-repeat center;
    background-color: white;
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-left:75px;
    border-radius: 4px;
}
.disabled-arrow-next {
    background: url(${
        uparrowdisabled
    }) no-repeat center;
    background-color: white;
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-left:75px;
    border-radius: 4px;
}
.plot-bottom-bar {
    display: table;
    height: 19px;
}
.col-custom {
    float: left;
}
.column-header .minimized {
    display: none;
}
.disabled-arrow-last {
    background: url(${
        downarrowdisabled
    }) no-repeat center center;
    background-color: white;
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-left: 8px;
    border-radius: 4px;
}
.property-type-bar-last {
    background: url(${
        downarrow
    }) no-repeat center center;
    background-color: white;
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-left: 8px;
    border-radius: 4px;
}
.property-number-tile-container {
    float: right;
    display: none;
    margin-top: 21px;
    width: 306px;
}
.collaborator-init-tile-container {
    float: right;
    display: none;
    margin-left: 1px;
    margin-top: 21px;
    width: 265px;
}
.column-header .minimized-property {
    display: block;
}
.property-number-tile {
    padding-top: 4px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    float: right;
    height: 26px;
    width: 50px;
    border-radius: 3px;
    background-color: #9EB4BE;
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    /* text - dark */
    color: #434552;
}
.collaborator-name-tile {
    padding-top: 4px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    float: left;
    height: 26px;
    width: 38px;
    border-radius: 3px;
    background-color: #CDAA89;
    margin-left: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    
    /* text - dark */
    color: #434552;
}
.tiles-wrapper {
    border-radius: 10px;
    margin-top: 20px;
    /* height: 70%;
    */
    overflow:auto;
}
.tile {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    cursor: pointer;
}
.action-button-small {
    width: 18px;
    height: 18px;
    float: left;
}
.toggle-collapsed-view {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
}
.toggle-minimized-view {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
}
.multifunction-menu-link {
    width: 22px;
    height: 20px;
    background: url(${
    multifunction_icon
    }) no-repeat center;
}
.multi-function-menu-vertical {
    width: 18px;
    height: 18px;
    background: url(${multifunction_vertical_icon}) no-repeat center;
}
.button-remove {
    position: relative;
    width: 18px;
    height: 18px;
    background: url(${
    button_remove_icon
    }) no-repeat !important;
    border: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 5;
}
.button-remove:hover {
    background: url(${
    button_remove_hover_icon
    }) no-repeat;
}
.button-remove-large {
    width: 35px;
    height: 35px;
    background: url(${
    button_remove_large_icon
    }) no-repeat !important;
    border: 0px;
    margin: 0px;
    padding: 0px;
}
.button-remove-large:hover {
    background: url(${
    button_remove_large_hover_icon
    }) no-repeat;
}
.button-return {
    width: 18px;
    height: 18px;
    border: 0px;
    margin: 0px;
    padding: 0px;
    background: url(${
    button_return_icon
    }) no-repeat !important;
}
.navigation-wrapper .controls {
    height: 36px;
    width: 72px;
    background: #B7B8BA;
}
.navigation-wrapper .controls .control {
    height: 36px;
    width: 36px;
    float: left;
     cursor: pointer;
}
.navigation-wrapper .controls .control.large {
    height: 72px;
    width: 72px;
    float: left;
}
.navigation-wrapper .controls .previous {
    background: url(${
    product_tile_item_navigate_previous
    }) no-repeat;
}
.navigation-wrapper .controls .next {
    background: url(${
    product_tile_item_navigate_next
    }) no-repeat;
}
.navigation-wrapper .controls .previous.large {
    background: url(${
    product_tile_item_navigate_previous_large_hover
    }) no-repeat;
}
.navigation-wrapper .controls .previous.large:hover {
    background: url(${
    product_tile_item_navigate_previous_large_hover
    }) no-repeat;
}
.navigation-wrapper .controls .next.large {
    background: url(${
    product_tile_item_navigate_next_large_hover
    }) no-repeat;
}
.navigation-wrapper .controls .next.large:hover {
    background: url(${
    product_tile_item_navigate_next_large_hover
    }) no-repeat;
}
.navigation-wrapper .counter {
    height: 36px;
    width: auto;
    float: left;
    background: #B7B8BA;
    padding: 0 8px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.6;
}
.editable-label {
     cursor: text;
}
.editable-input {
     /* border: none;
    background-color: transparent;
     outline: none;
     text-align: left;
    margin: 0; */
}
.image-preview-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}
.image-preview-wrapper .image-preview {
    float: left;
    margin-right: 1px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 10px;
}
.clickble {
     cursor: pointer;
}
.disabled {
     cursor: not-allowed;
     color: #eee !important;
}
.dropdown-item {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #7C7C82;
}
#myAnchor {
    width: 0px;
    height: 0px;
    outline: none;
    border: none;
    font-size:0px;
    line-height: 0px;
    padding: 0px;
    border-width: 0px !important;
}
.multi-function-menu-horizontal {
    background: url(${multifunction_horizontal_icon}) no-repeat center;
    width: 18px;
    height: 18px;
}
.modal-title .h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #434552;
    margin: 0 auto;
}
.modal-title .h4 .iconed {
    padding-left: 47px;
}
.modal-header {
    border-bottom: 2px solid #e0e0e0;
    height: 60px;
}
.product-add-modal .modal-header {
    padding-top: 7px !important;
}
.product-add-modal .modal-body {
    padding-bottom: 0px !important;
    padding-top: 12px !important;
}
.product-add-modal .modal-header {
    height: 60px;
    width: 710px;
    background-color: #4a657c;
    border-radius: 10px 10px 0px 0px;
}
.product-add-modal .modal-content {
    border-radius: 15px 15px 0px 0px;
    border: 0px solid black !important;
}
.login-modal .modal-content {
    margin-top: 95px;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal.show .modal-dialog {
    margin-top: 95px;
}
.product-edit-modal .modal-content {
    border-radius: 15px 15px 0px 0px;
    border: 0px solid black !important;
}
.product-edit-modal .modal-header {
    border-bottom: 2px solid #4a657c;
    height: 60px;
    width: 710px;
    background-color: #4a657c;
}
.modal-header .close {
    padding: 0;
    margin: 0;
}
.modal-dialog .close {
    float: right;
    width: 35px;
    height: 35px;
    margin-top: -3px;
    margin-right: 10px;
    background: #fff url(${
    modal_button_close
    }) no-repeat;
    cursor: pointer;
}
.product-add-modal-close {
    float: right;
    width: 36px;
    height: 36px;
    margin-top: 5px;
    margin-right: 10px;
    background: #fff url(${
    modal_button_close
    }) no-repeat;
    cursor: pointer;
    border-radius: 5px;
}
.edit-model-foter-item {
    width: 710px;
    height: 543px;
    background: #C3DBF6;
    border-radius: 5px;
    marging-top: 4px;
    overflow-y: scroll;
    display: none;
}
.edit-model-foter-item-open {
    width: 710px;
    height: 543px;
    background: #C3DBF6;
    border-radius: 5px;
    marging-top: 4px;
    overflow-y: scroll;
    display: block;
}
.item-wrapper-edit {
    width: 615px;
    margin-left: 62px;
    margin-top: 25px;
    display: none
}
.item-wrapper-edit-open {
    width: 615px;
    margin-left: 62px;
    margin-top: 25px;
    display: block
}
.item-tile-edit {
    float: left;
    margin-right: 8px;
    width: 115px;
    height: 115px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 8px;
    background-color: white;
}
.modal-dialog .close:hover {
    background: #fff url(${
    modal_button_close_hover
    }) no-repeat;
}
.modal-dialog .close.small {
    background: #fff url(${
    modal_button_small_close
    }) no-repeat;
}
.modal-dialog .close.small:hover {
    background: #fff url(${
    modal_button_small_close_hover
    }) no-repeat;
}
.product-icon {
    background: url(${
    product_icon
    }) no-repeat;
    float: left;
    width: 32px;
    height: 32px;
}
.solid-left-product {
    background: url(${
        solid_left_product
    }) no-repeat;
    width: 20px;
    height: 20px;
}
.solid-right-product {
    background: url(${
        solid_right_product
    }) no-repeat;
    width: 20px;
    height: 20px;
}
.specs-icon {
    background: url(${
        specs_icon
    }) no-repeat;
    height: 32px;
    margin-top: 8px;
    margin-left: 13px;
}
.item-icon {
    background: url(${
        item_icon
    }) no-repeat;
    height: 32px;
    margin-top:9px;
    margin-left: 15px;
    cursor: not-allowed;
}
.market-icon {
    background: url(${
    market_icon
    }) no-repeat;
    height: 32px;
    margin-top:9px;
    margin-left: 8px;
    cursor: not-allowed;
}
.date-icon {
    background: url(${
        date_product_icon
    }) no-repeat;
    height: 32px;
    margin-top:9px;
    margin-left: 11px;
    cursor: not-allowed;
}
.product-tab p {
    float: left;
    width: 83.8px;
    height: 20px;
    margin-left: 10.9px;
}
.modal-body {
    padding: 25px;
}
.modal-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #7C7C82;
    margin-bottom:30px;
}
.modal-body h5 {
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    text-align: left;
    color: #434552;
    margin-bottom:10px;
}
.modal-body .form-group {
    padding-left:37px;
    margin-bottom:9px;
}
.modal-body .form-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #434552;
    padding-left: 37px;
    margin-left:-37px;
    margin-bottom:3px;
}
.modal-body .form-label.title {
    background: #fff url(${
    modal_label_title
    }) no-repeat;
}
.modal-body .form-label.email {
    background: #fff url(${
    modal_label_email
    }) no-repeat;
}
.modal-body .form-label.description {
    background: #fff url(${
    modal_label_description
    }) no-repeat;
}
.modal-body .form-label.image {
    background: #fff url(${
    modal_label_image
    }) no-repeat;
}
.modal-body .form-label.key {
    background: #fff url(${
    modal_label_key
    }) no-repeat;
}
.modal-body .form-label.house {
    background: #fff url(${
    modal_label_house
    }) no-repeat;
}
.modal-body .form-label.landmark {
    background: #fff url(${
    modal_label_landmark
    }) no-repeat;
}
.modal-body .form-label.link {
    background: #fff url(${
    modal_label_link
    }) no-repeat;
}
.modal-body .form-label.company {
    background: #fff url(${
    modal_label_company
    }) no-repeat;
}
.modal-body .form-label.building {
    background: #fff url(${
    modal_label_building
    }) no-repeat;
}
.modal-body .form-label.share {
    background: #fff url(${
    modal_label_share
    }) no-repeat;
}
.modal-body input {
    height: 54px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #A4A4AC;
}
.modal-body select {
    height: 54px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #A4A4AC;
}
.product-add-modal .modal-body .addimages {
    height: 0px;
    border: 0px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 0px;
    line-height: 0px;
    display: flex;
    align-items: center;
    color: #A4A4AC;
}
.submit-add-product {
    box-sizing: border-box !important;
    border-radius: 10px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 19px !important;
    /* text - mid */
}
.modal-body .custom-file-label {
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #A4A4AC;
    height: 54px;
}
.image-product-add {
    margin-top: 2px !important;
    margin-left: 0px !important;
    padding-left: 9px !important;
    width: 110px;
    height: 54px;
    background: #317CD3;

    /* confirm percentage */
    border: 2px solid #83B1E8;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;

    /* white */
    color: #FFFFFF !important;
}
.modal-body .custom-file-label:after {
    border-radius: 0 8px 8px 0;
    height: 50px;
    line-height: 40px;
}
.modal-body .form-group {
    height: 80px;
}
.modal-body .form-group.submit {
    height: 54px;
    margin-bottom: 0px;
    clear: both;
    padding-left: 0px;
}
.modal-body .btn.submit {
    margin-left: 38px;
}
.modal-body .btn.submit.maximum {
    margin-left: 0px;
}
.btn.submit {
    background: #317CD3;
    border: 2px solid #83B1E8;
    border-radius: 10px;
    width: 111px;
    box-sizing: border-box;
    color: #fff;
    height: 54px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
}
.btn.submit:active {
    background: #564292 !important;
    border: 2px solid #9687C0 !important;
}
.btn.submit.minimum {
    width: 81px;
}
.btn.submit.medium {
    width: 261px;
}
.btn.submit.maximum {
    width: 100%;
}
.btn.submit.disabled {
    background: #F6F6F6;
    color: #7C7C82 !important;
    border: 2px solid #E0E0E0;
    cursor: not-allowed;
}
.btn.submit.disabled:active {
    background: #F6F6F6 !important;
    border: 2px solid #E0E0E0 !important;
}
.popover {
    background: #FFFFFF;
    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 280px;
}
.popover-header {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #434552;
    background: none;
    margin: 11px 14px 0 14px;
    height: 30px;
    border-bottom: 2px solid #E0E0E0;
    padding: 0 0 11px 0;
}
.popover .close {
    float: right;
    width: 18px;
    height: 18px;
    background: #fff url(${
    modal_button_small_close
    }) no-repeat;
    cursor: pointer;
    margin-top: 1px;
}
.popover .close:hover {
    background: #fff url(${
        modal_button_small_close_hover
    }) no-repeat;
}
.popover-body {
    padding: 12px 14px 14px 14px;
}
.product-header-wrapper {
    width: 692px;
    margin: auto;
}
.popover input {
    height: 48px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}
.popover .btn {
    height: 48px;
    margin-top: 4px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
}
.popover.delete p {
    font-size: 12px;
    line-height: 16px;
    color: #D33131;
    margin-bottom: 0px;
}
.popover.delete .btn {
    background: #D33131;
    margin-top: 22px;
    border: 2px solid #FF7878;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
}
.popover.delete {
    border: 2px solid #D33131;
}
.bs-popover-bottom.delete .arrow:before {
  border-bottom-color: #D33131 !important;
}
.bs-popover-bottom.delete .arrow:after {
  border-bottom-color: #D33131 !important;
}
.modal-dialog .close {
    opacity:1 !important;
}
.modal-dialog .close:hover {
    opacity:1 !important;
}

/* Property Column*/

.property-column {
    width: 297px;
    height: 100%;
    min-height: 100%;
     transition: width 400ms;
}
.property.column-header {
     /* background: #738C97; */

    background: #fff;
     color: #7C7C82;
    padding: 25px 16px 23px 16px;
}
.property.column-header div.logo {
    width: 120px;
    height: 20px;
    float: left;
}
.firm-edit-modal .image-previews-wrapper {
    height: 55px;
}
.firm-edit-modal .image-preview-wrapper {
    width: 150px;
    height: 50px;
    background: #eee;
    padding: 15px;
}
.firm-edit-modal .image-preview-wrapper .image-preview {
    max-width: 120px;
    max-height: 20px;
    border-radius: 0px;
}
.property.column-header h2 {
    width: 140px;
    height: 16px;
     text-align: right;
    float: right;
    font-size: 14px !important;
}
.property-action-bar-wrapper {
    padding-left: 12px;
    padding-right: 14px;
    width: 294px;
}
.property.action-bar .create-property {
    background: #fff;
    /* box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    */
    border-radius: 10px 0 0 10px;
    padding:9px 0 8px 12px;
    width: 199px;
    float: left;
    height: 68px;
     color: #434552;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.property.action-bar .create-property-icon {
    content: url(${
        create_property_icon
    });
    float: right;
    width: 69px;
    height: 68px;
}
.property.action-bar .create-property-icon-PLOT {
    content: url(${
       create_property_icon_plot
    });
    float: right;
    width: 69px;
    height: 68px;
}
.property.action-bar .create-property-icon-SITE {
    content: url(${
       create_property_icon_site
    });
    float: right;
    width: 69px;
    height: 68px;
}
.property.action-bar .create-property-icon-ROOM {
    content: url(${
        create_property_icon_room
    });
    float: right;
    width: 69px;
    height: 68px;
}
.property.tiles-wrapper {
    background: rgba(158, 180, 190, 0.5) url(${
    property_background_icon
    });
    padding-top: 20px;
    scrollbar-width: thin;
    scrollbar-color: #738c97 #ccd5d9;
}
.property.tiles-wrapper::-webkit-scrollbar {
  width: 8px;
}
.property.tiles-wrapper::-webkit-scrollbar-track {
  background: #ccd5d9;
}
.property.tiles-wrapper::-webkit-scrollbar-thumb {
  background-color: #738c97;
  border-radius: 8px;
}
.property-limit-warpper {
    margin-top: -32px;
    height: 24px;
    position: absolute;
    margin-left:77px !important
}
.property .full-to-limited {
    width: 56px;
    height: 24px;
     cursor: pointer;
    background: url(${
    properties_full_to_limited_view_icon
     }) no-repeat;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    float: left;
}
.property .full-to-minimized {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
     cursor: pointer;
    background: url(${
    properties_full_to_minimized_view_icon
     });
    float: left;
    margin-left: 60px;
}
.property-limit-warpper-limited {
    margin-top: 0px !important;
    margin-left: 0px !important;
}
.property-limit-warpper-minimized {
    margin-left: 0px !important;
    position: unset;
}
.property .limited-view-toggle-wrapper {
    background: url(${
    properties_limited_view_wrapper_icon
    }) no-repeat;
    width: 56px;
    height: 24px;
    left:71px;
    position:absolute;
    margin-top: -32px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.property .limited-to-full {
    width: 24px;
    height: 24px;
     cursor: pointer;
    float: right;
}
.property .limited-to-minimized {
    width: 24px;
    height: 24px;
     cursor: pointer;
    float: left;
}
.property .minimized-to-limited {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: #fff url(${
    properties_minimized_to_limited_view_icon
    }) no-repeat;
    top: 224px;
    border-radius: 0px 4px 4px 0;
}
.property-type-bar {
    background-color: #5D84B1;
    width: 243px;
    height: 46px;
    margin-left: 25px;
    border-bottom-left-radius: 8px ;
    border-bottom-right-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 2px;
    padding-top: 13px;
}
.type-width {
    width: 88px;
}
.plot-type-container {
    font-style:normal;
    font-size: 13px;
    color:white;
    width: 86px !important;
    height: 19px !important;
}
.limited .property-type-bar {
    background-color: #5D84B1;
    width: 96px;
    height: 46px;
    margin-left: 0px;
    border-bottom-left-radius: 0px ;
    border-bottom-right-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
}
.limited .col-custom {
    display: none;
}
.limited .property-type-bar .prop-content {
    display: none;
}
.limited .createhere {
    display: none;
}
.minimized .property-type-bar {
    background-color: #5D84B1;
    width: 243px;
    height: 46px;
    margin-left: -115px;
    border-bottom-left-radius: 8px ;
    border-bottom-right-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 2px;
    display: none;
}
.property .minimized-to-full {
    width: 24px;
    height: 56px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: #fff url(${
    properties_minimized_to_full_view_icon
    }) no-repeat;
    top: 164px;
    border-radius: 0px 4px 4px 0;
}
.property-add-modal {
    max-width: 800px !important;
    width: 710px ;
}
.property-add-modal .modal-header {
    background: transparent url(${
    modal_property_add_corner_background
    }) no-re
}
.property.modal-title .h4 {
    background: #fff url(${
    property_add_modal_title_icon
    }) no-re
}
.property.modal-title .h4 {
    background: #fff url(${
    property_add_modal_title_icon
    }) no-re
}
.property-add-modal .image-previews-wrapper {
    height: 115px;
}
.property-add-modal .image-preview-wrapper {
    padding: 0px;
    width: 117px;
    height: 117px;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    border-radius: 10px;
}
.property-add-modal .image-preview-wrapper .image-preview {
    max-width: 115px;
    max-height: 115px;
    border: none;
}
.property-edit-modal .image-previews-wrapper {
    height: 150px;
}
.property-edit-modal .image-preview-wrapper {
    width: 150px;
    height: 150px;
    background: #eee;
    padding: 15px;
    border-radius: 10px;
}
.property-edit-modal .image-preview-wrapper .image-preview {
    max-width: 120px;
    max-height: 120px;
    border-radius: 0px;
}
.property-edit-modal .btn.submit {
    margin-left: 37px;
}
.property.tile {
    margin: 0px 0px 15px 10px;
    box-shadow: none;
    background: none;
}
.property-tile-content {
    width: 130px;
    float: left;
    height: 112px;
    background: #fff;
    border-radius: 10px 0px 0px 0px;
}
.thumb-second-triangle {
    position: relative;
    right: 19px;
    z-index: 1;
    top: 12px;
}
.thumb-third-triangle {
    position: relative;
        top: 6px;
    right: 9px;
    z-index: 2;
}
.thumb-first-triangle {
    position: relative;
    top:0px;
    z-index: 3;
}
.thumb-first-hexagon {
    position: relative;
    bottom:16px;
    z-index: 3;
    left:15px;
}
.property-tile-content .quality-indicator-wrapper {
    height: 12px;
    width: 56px;
    margin-bottom:22px;
    margin-top: 22px;
    margin-left: 8px;
}
.property-tile-content .quality-indicator {
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    margin-right: 10px;
    border-radius: 50%;
    float: left;
}
.quality-indicator.green {
    border:1px solid #00A865;
}
.quality-indicator.yellow {
    border:1px solid #FFC796;
}
.quality-indicator.red {
    border: 1px solid #FF7878;
    margin: 0px;
}
.property-tile-content .notification-counter {
    width: 35px;
    height: 18px;
    background: transparent url(${
    plot_tile_notification_counter_placeholder    }
    ) no-re
    margin-left: 8px;
}
.property-tile-content .dropdown {
    height: 20px;
    margin-top: 10px;
    margin-left: 8px;
}
.property-tile-content .property.multifunction-menu-link {
    float: left;
    height: 20px;
}
.property-thumbnail-triangle-wrapper {
    float: left;
    width: 147px;
    height: 112px;
    background: transparent url(${
    property_tile_triangle_background
    }) no-repeat 0 0;
}
.property-thumbnail-squre-wrapper {
    float: left;
    width: 131px;
    height: 112px;
    background: transparent url(${
    property_tile_squer_background
    }) no-repeat 0 0;
}
.property-thumbnail-triangle-wrapper .property.button-remove {
    margin: -18px 0 -26px 8px;
}
.property-thumbnail-triangle-wrapper img {
    width: 116px;
    height: 101px;
    float: left;
    margin-top: -20px;
    margin-left: 6px;
    position: absolute;
}
.property-thumbnail-squre-wrapper .button-remove btn {
    margin-top: 2px !important;
}
.property.tile.selected .property-thumbnail-squre-wrapper .button-remove {
    top: 6px;
}
.thumb-third-triangle img {
    width: 70px !important;
    height: 70px !important;
    top: 12px !important;
}
.thumb-second-triangle img {
    width: 50px !important;
    height: 50px !important;
    top: 12px !important;
}
.property-details-wrapper {
    width: 195px;
    height: 36px;
    padding: 7px 3px;
    border-radius: 0px 0px 10px 10px;
    background: #738C97;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.property-meta{
    height: 19px;
    color: #000;
    font-size: 14px;
    font-weight: 800;
}
.property-meta .title {
    width: 127px;
    height: 22px;
    overflow: hidden;
}
.property-meta .plot-number {
    width: 54px;
    text-align:right;
    height: 22px;
    overflow: hidden;
}
.property.tile.selected .thumb-first-hexagon {
    width: 120px;
    height: 101px;
    float: left;
    margin-top: 6px;
    margin-left: 0px;
    position: relative;
}
.clearfix{
    z-index: 2
}
.property-meta .plot-name-editable-label {
    line-height: 19px;
    font-size: 14px;
    font-weight: bold;
    cursor: text;
    margin-left: 3px;
    height: 22px;
    padding-top: 2px;
}
.property-meta .plot-name-editable-input {
    border: 2px solid #317CD3;
    border-radius: 5px;
    padding: 3px;
    outline: none;
    margin: none;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    width: 127px;
    height: 22px;
    float: left;
}
.property-meta .plot-number-editable-label {
    line-height: 19px;
    font-size: 14px;
    font-weight: bold;
    cursor: text;
    margin-right:5px;
    float: right;
    height: 22px;
    padding-top: 2px;
}
.property-meta .plot-number-editable-input {
    border: 2px solid #317CD3;
    border-radius: 5px;
    padding: 3px;
    outline: none;
    margin: none;
    font-weight: bold;
    font-size: 14px;
    text-align:right;
    width: 54px;
    height: 22px;
    float: right;
}
.property-details {
    width: 256px;
}
.property-details .action-button-wrapper {
    border-top: 1px solid #4A657C;
    margin: 0 6px;
    height: 26px;
    padding: 8px 4px 0px 4px;
    width: 244px;
}
.property-details .action-button-wrapper .button-return {
    float: right;
}
.property-details-title {
    color: #434552;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    padding: 1px 0 1px 21px;
    margin: 0px;
    margin-left: 6px;
}    
.uprn {
    background: url(${uprn_icon }) no-repeat left center;
}
.property-type {
    background: url(${property_type_icon }) no-repeat left center;
}
.deed {
    background: url(${property_type_icon }) no-repeat left center;
}
.notes {
    background: url(${property_type_icon }) no-repeat left center;
}
.safety {
    background: url(${property_type_icon }) no-repeat left center;
}
.lrid {
    background: url(${property_type_icon }) no-repeat left center;
}
.date {
    background: url(${property_type_icon }) no-repeat left center;
}
.w3w {
    background: url(${property_type_icon }) no-repeat left center;
}
.property-details-value {
    height: 26px;
    margin: 0px;
}
.property-details-value label {
    font-size: 12px;
    font-color: #000;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
    height: 26px;
    padding: 4px 2px;
    margin: 0px;
    margin-left: 8px;
    
    
}
.plot-details-editable-label {
    font-size: 12px;
    font-color: #000;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
    height: 26px;
    padding: 4px 2px;
    margin: 0px;
    margin-left: 8px;
    cursor: text;
}
.property-detail-element {
    margin-bottom: 8px;
    height: 40px;
}
.plot-details-editable-input {
    border: 2px solid #317CD3;
    border-radius: 5px;
    padding: 5px 6px;
    outline: none;
    margin: none;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 256px;
    height: 26px;
}

/* Property Tile Selected */

.property.tile.selected {
    margin: -6px 0 51px 4px;
    height: 118px;
}
.property.tile.selected .property-tile-content {
    border-top: 6px solid #317CD3;
    border-left: 6px solid #317CD3;
    height: 118px;
    width: 136px;
}
.property.tile.selected .property-thumbnail-triangle-wrapper {
    background: url(${
    property_tile_triangle_selected_blue_background
     });
    height: 124px;
    width: 137px;
}
.property.tile.selected .property-thumbnail-squre-wrapper {
    background: url(${
    property_tile_hexagon_selected_blue_background
    });
    height: 124px;
    width: 137px;
    background-repeat: no-repeat;
}
.property.tile.selected .property-thumbnail-triangle-wrapper img {
    margin-top: -14px;
}
.property.tile.selected .property-thumbnail-triangle-wrapper .property.button-remove {
    margin: -12px 0 -32px 8px;
}
.property.tile.selected .property-thumbnail-triangle-wrapper .property.button-remove:hover {
    background: url(${
    button_remove_hover_icon
    }) no-repeat;
}
.property.tile.selected .property-details-wrapper {
    border: 6px solid #317CD3;
    border-top: none;
    height: 38px;
    width: 207px;
    margin-top: -6px;
}
/* Property Tile Detailed View*/
.property.tile.detail_view {
    margin: -6px 0 456px 4px;
    height: 118px;
     transition: margin-bottom 700ms;
}
.property.tile.detail_view .property-details {
     display: block;
}
.property.tile.detail_view .property-tile-content {
    border: 6px solid #94529C;
    border-right: none;
    height: 124px;
    border-radius: 10px 0 0 10px;
    width: 111px;
}
.property.tile.detail_view .property-thumbnail-triangle-wrapper {
    background: url(${
    property_tile_triangle_selected_purple_background
     });
    height: 124px;
    width: 137px;
}
.property.tile.detail_view .property-thumbnail-triangle-wrapper img {
    margin-top: -14px;
}
.property.tile.detail_view .property-meta{
    width: 244px;
    margin: -2px 4px 0 4px;
}
.property.tile.detail_view .property-meta .plot-name-editable-label {
    margin-left: 2px;
}
.property.tile.detail_view .property-thumbnail-triangle-wrapper .property.button-remove {
    margin: -4px 0 -32px 8px;
}
.property.tile.detail_view .property-details-wrapper {
    border: none;
    width: 260px;
    height: 440px;
    margin-left: 4px;
    margin-top: -4px;
    padding: 7px 2px;
}
/* Property Tiles Limited */
.property-column.limited {
    width: 157px;
}
.property-column.limited .property.tile {
    width: 137px;
    margin-left: 0px;
    padding-left: 0px;
}
.property-column.limited .property-tile-content {
     display: none;
}
.property-column.limited .property-meta{
    width: 65px;
}
.property-column.limited .property-details-wrapper {
    border: none;
    width: 65px;
    margin-left: 0px;
}
.property-column.limited  .property.tile.selected .property-details-wrapper {
    border: 6px solid #317CD3;
    border-top: none;
    height: 38px;
    margin-top: -6px;
    width: 71px;
    margin-left: 0px;
}
.property-column.limited .property-meta .plot-number {
    float:none !important;
}
.property-column.limited .create-property span {
     display: none;
}
.property-column.limited .property-action-bar-wrapper {
    width: 157px;
    padding-left: 0px;
    padding-right: 14px;
}
.property-column.limited .create-property {
    width: 74px;
    padding-left: 0px;
    border-radius: 0;
}
.property-column.limited .column-header {
    width: 157px;
    border-radius: 0 0 10px 0;
}
.property-column.limited .column-header h2 {
     display: none;
}

/* Property Tiles Minimized */

.property-column.minimized {
    width: 34px;
}
.property-column.minimized .property.tiles-wrapper {
    background: none;
}
.property-column.minimized .property.tile, .property-column.minimized .property-action-bar-wrapper, .property-column.minimized .column-header {
     display: none;
}
/* Property Column Handler*/
.property-column-handle-wrapper {
    width: 59px;
    float: left;
     display: none;
}

/********************************/
/* Product Column */
/********************************/

.product-column {
    padding: 0 4px;
}
.product.column-header {
    background: #4A657C;
    padding: 0px;
    width: 692px;
    margin: 0 auto 20px auto;
}
.product.column-header h2 {
    padding: 25px 0 26px 16px;
     text-align: left;
    float: left;
    height: 16px;
    font-size: 14px !important;
}
.product.column-header .settings {
    padding: 21px 16px 21px 0;
    float: right;
    height: 68px;
    width: 57px;
    background: url(${
        settings_icon
     }) no-repeat center;
     cursor: pointer;
}
.product.column-header .company-logo {
    padding-top: 9px;
    height: 66px;
    background: url(${
        company_logo
     }) no-repeat center;
}
.minimized-company-logo {
    padding-top: 9px;
    width: 48px;
    height: 66px;
    background: url(${
       company_logo
    }) no-repeat center;
    float: right;
}
.product.action-bar {
    background: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 3px 0px 3px 0px;
    border-radius: 10px;
    width: 640px;
}
.product.action-bar .create-product-drag-drop-icon {
    width: 193px;
    height: 62px;
    background: url(${
        add_product_drag_drop
    }) no-repeat center top;
    float: left;
    padding: 0 38px;
    background-color: #eee;
    border:1px dashed #ccc;
    margin: 0 3px;
    border-radius: 8px;
}
.product.action-bar .create-product-text {
    border-left: 1px solid #B7B8BA;
    float: left;
    font-weight: 600;
    font-size: 16px;
    color: #434552;
    padding: 19px 0 21px 12px;
}
.create-product-type-icon-wrapper {
    width: 306px;
    float: right;
    height: 58px;
}
.product.action-bar .create-product-type-icon {
    width: 60px;
    height: 62px;
    float: left;
    margin-right:2px;
}
.product.action-bar .product-type-wrapper-open .create-product-type-icon {

}
.product-type-wrapper .button-wrapper-type .compliance {
    width: 60px;
    height: 62px;
    float: left;
    margin-left: 8px;
    margin-top: 5px;
}
.product-type-wrapper .button-wrapper-type .legal {
    width: 60px;
    height: 62px;
    float: left;
    margin-left: 5px;
    margin-top: 5px;
}
.product-type-wrapper .button-wrapper-type .module {
    width: 60px;
    height: 62px;
    float: left;
    margin-left: 5px;
    margin-top: 5px;
}
.product.action-bar .create-product-type-icon. compliance {
    background: url(${
        add_product_compliance
    }) no-repeat;
}
.product.action-bar .create-product-type-icon .compliance_selected {
    background: url(${
        add_product_compliance_selected
    }) no-repeat;
}
.specs-field-list-add-product-open {
    display: block;
}
.specs-field-list-add-product-close {
    display: none;
}
.product.action-bar .create-product-type-icon .legal {
    background: url(${
        add_product_legal
    }) no-repeat;
}
.product.action-bar .create-product-type-icon .legal_selected {
    background: url(${
       add_product_legal_selected
    }) no-repeat;
}
.product.action-bar .create-product-type-icon .module {
    background: url(${
        add_product_module
     }) no-repeat;
}
.product.action-bar .create-product-type-icon .module_selected {
    background: url(${
       add_product_module_selected
    }) no-repeat;
}
.product-add-modal {
    max-width: 800px !important;
    width: 710px ;
}
.product-edit-modal {
    max-width: 710px !important;
    width: 710px !important;
}
.product-edit-modal .modal-body {
    padding-bottom: 0px !important;
}
.product-edit-modal .add-product-wrapper-open {
    height: 77px !important;
}
.product-edit-modal .product-add-spec-container {
    height: 77px !important;
}

/*
.product-edit-modal .edit-save {
    margin-top: 25px !important;
    margin-left: 30px !important;
}
*/

.product-edit-modal .modal-body {
    height: auto !important;
}
.product-share-modal {
    max-width: 800px !important;
    width: 710px ;
}
.product.modal-title .h4 {
    background: #fff url(${product_add_modal_title_icon}) no-repeat;
}
.product-add-modal .image-previews-wrapper {
    width: 660px;
}
.product-add-modal .image-preview-wrapper {
    width: 115px;
    height: 115px;
    margin: 0 12px 12px 0;
    border-radius: 10px;
    float: left;
    background: #efefef;
}
.product-add-modal .image-preview-wrapper.last {
    margin-right: 0px;
}
.product-add-modal .image-preview {
    max-width: 115px;
    max-height: 115px;
}
.product.action-bar .create-product-type-icon.marketplace {
    width: 98px;
    height: 60px;
    background: url(${
    product_marketplace
    }) no-repeat center;
    float: right;
    border-left: 1px solid #B7B8BA;
    padding-left: 14px;
}
.product.tiles-wrapper {
    /* background: rgba(148, 171, 190, 0.5);
    */
    padding: 7px 0px;
    scrollbar-width: thin;
    scrollbar-color: #4A657C #94abbe;
}
.product.tiles-wrapper::-webkit-scrollbar {
    width: 8px;
}
.product.tiles-wrapper::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #94abbe;
}
.product.tiles-wrapper::-webkit-scrollbar-thumb {
    background-color: #4A657C;
    border-radius: 8px;
}
.login-wrapper {
    background-color: #000 !important;
    height: 100% !important;
    width: 100% !important;
}
.product .tile {
    height: 232px;
    background: #94ABBE;
    width: 640px;
    margin-bottom:20px;
}
.product-tile-header {
    background: #94ABBE;
    height: 32px;
    border-radius: 10px;
    padding: 4px 8px;
    text-align: center;
}
.product-tile-header h3 {
    font-weight: 800;
    font-size: 14px;
    color: #000000;
    padding: 0px 0 4px 0;
    width: 415px;
    text-align: center;
    margin: 0;
    height: 24px;
    float: left;
}
.product-tile-header .multi-function-menu-horizontal {
    float: right;
    margin-top: 4px;
}
.product-tile-header .display-mode-toggle-wrapper {
    background: #FFFFFF;
    border-radius: 5px 5px 0px 0px;
    width: 104px;
    height: 28px;
    float: left;
    margin-right: 65px;
    padding: 4px 7px 6px 7px;
}
.product-tile-header .display-mode-toggle-wrapper .view-toggle {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    float: left;
    width: 18px;
    height: 18px;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
}
.product-tile-header .display-mode-toggle-wrapper .view-toggle .left {
    border-radius: 3px 0px 0px 3px;
}
.product-tile-header .display-mode-toggle-wrapper .view-toggle .right {
    border-radius: 0px 3px 3px 0px;
}
.product-tile-header .product-title-editable-label {
    padding-top: 5px;
    margin-left: 8px;
    width: 399px;
    margin-right: 8px;
    cursor: text;
}
.product-tile-header .product-title-editable-input {
    border: 2px solid #317CD3;
    border-radius: 3px;
    padding: 2px;
    outline: none;
    margin: none;
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    width: 407px;
    height: 24px;
    float: left;
    margin-left: 4px;
    margin-right: 4px;
}
.category-type-header {
    position: relative;
    left: 17px;
    top: 7px;
    width: 206px;
    height: 36px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0px 6px 5px rgb(0 0 0 / 20%);
}
.category-type-header .cat-type {
    width: 190px;
    height: 28px;
    margin-top: 5px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    /* identical to box height */
    margin: auto;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
    padding-top: 2px;

    /* text - mid */
    color: #7C7C82;

    /* standard drop shadow */
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.compliance-category-model {
    width: 206px;
    position: relative;
    top: 0px;
    left: 333.4px;
    background-color: white;
    z-index: 1;
    padding: 5px;
    height: 248px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 0px;

    /* border-bottom: solid #CCD5D9 2px;
    border-right: solid #CCD5D9 2px;
    border-left: solid #CCD5D9 2px; */
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    /* text - mid */
    color: #7C7C82;
}
.compliance-category-model .dropdown-item:hover {
    background-color: #5D84B1 ;
    color: white;
}
.compliance-category-model::-webkit-scrollbar {
    width: 8px;
}
.compliance-category-model::-webkit-scrollbar-track {
    background: #BDBDBD;
    border-radius: 4px;
}
.compliance-category-model::-webkit-scrollbar-thumb {
    background-color: #A4A4AC;
    border-radius: 8px;
}

.compliance-category-model .dropdown-item {
    padding: 0px;
    height: 24px;
    padding-left:8px;
    padding-top:4px
}
.category-Tile {
    height: 50px;
    background-color: #CCD5D9;
}

/* 
.legal-category-model {
    height: 300px;
    width: 300px;
    position: relative;
    top: 67px;
    left: 324px;
    background-color: black;
}
.module-category-model {
    height: 300px;
    width: 300px;
    position: relative;
    top: 67px;
    left: 324px;
    background-color: red;
} 
*/

.poster-left {
    height: 300px;
    width: 300px;
    background-color: #007647;
    float: left;
    margin-top: 485px;
}
.model-header-wrapper {
    margin: auto;
}
.product-header-tab:hover {
    cursor: pointer;
}
.product-edit-modal .model-header-wrapper-edit {
    width: 620px;
    margin: auto;
    margin-top: -9px;
}
.product-add-modal .model-header-wrapper {
    width: 624px;
    margin: auto;
}
.product-type-wrapper {
    height: 68px;
    width: 206px;
    float: left;
    margin-top: -3px;
}
.marketplace-wrapper {
    width: 90px;
    float: left;
    margin-left: 10px;
}
#formcheck-api-custom {
    width: 10px;
}
.add-product-wrapper-open {
    display: block;
    height: 106px !important;
}
.add-product-wrapper-closs {
    display: none;
}
.add-specs-wrapper-open {
    display: block;
    height: 97px !important;
}
.product-edit-modal .add-specs-wrapper-open {
    height: 77px !important;
}
.add-image-div-close {
    display: none;
}
.add-image-div-open {
    display: block;
}
.specs-field-list-add-product {
    height: 144px;
    background-color: #B5D0F1 ;
    width: 710px;
    margin-top: 25px;
    margin-left: -25px;
    margin-bottom: -25px;
    border-radius: 0px 0px 10px 10px !important;
    padding-top: 3px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.product-disc-minized {
    height: 34px !important;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0px 0px 10px 10px !important;
}
.product-disc-minized .text-box{
    background-color: #B5D0F1 !important;
    border:1px solid #B5D0F1 !important;
    padding-left: 0px !important;
    padding-top: 3px !important;
}
.specs-field-list-add-product::-webkit-scrollbar {
    width: 8px;
}
.specs-field-list-add-product::-webkit-scrollbar-track {
    background: #B5D0F1;
    border-radius: 4px;
}
.specs-field-list-add-product::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 8px;
}
.product-description .title {
    float: left;
    width: 100px;
    margin-left: 27px !important;
    background-color: #B5D0F1 !important;
    font-size: 12px;
    margin-top: 1px;
}
.product-description .text-box{
    float: left; 
    width: 538px;
    margin-left: 26px;
    margin-top: 1px;
    border: 1px solid #317CD3;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    /* black */

    color: #000000;
    height: 136px !important;
}
.specs-field {
    width: 657px;
    height: 16px;
    margin-left: 26px;
    font-size: 12px;
    margin-top: 8px;
}
.field-name {
    float: left;
    width: 96px;
    height: 16px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;

    /* text - dark */

    color: #434552 !important;
}
.field-input {
    float: left;
    width: 532px;
    height: 16px;
    margin-left: 26px;
}
.is-open {
    background-color: white;
    border:1px solid #317CD3 !important;
}
.not-open {
    background-color: #B5D0F1;
    border:1px solid #B5D0F1 !important;
}
.field-input .input {
    height: 20px;
    width: 532px;
    font-size: 12px;
    border-radius: 5px !important;
    color: #434552  !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center !important;

    /* black */
    color: #000000;
}
.add-specs-wrapper-closs {
    display: none;
}
.product-add-spec-container {
    height: 97px !important;
    left: 25px;
    margin-top: 10px;
}
.product-type-wrapper-open {
    background-color: #5D84B1;
    border-radius: 10px;
}
.button-wrapper-type {
    width: auto;
    background-color: red;
    margin: auto;
}
.product-add-spec-container .form-label {
    margin: 0px !important;
}
.specs-type-textbox .form-control {
    width: 200px !important;
    height: 54px !important;
    margin-left: 37px !important;
}
.product-add-modal .add-image-div{
    margin-top: 10px;
}
.specs-type-textbox{
    float: left;
    width: 193px ;
    margin-left: 26px;
}
.specs-name-textbox{
    float: left;
    width: 392px ;
}
.specs-name-textbox .form-control {
    width: 362px !important;
    height: 54px !important;
    margin-left: 31px !important;
}
.product-add-header-specs {
    width: 658px;
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 37px;
    background: #E6E6E6;
    font-size: 14px;
    font-weight: bold;

    /* lines */
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid #E0E0E0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* text - mid */
    color: #7C7C82;
}
.product-tab {
    float: right;
    width: 196px;
    height: 51px;
    background-color: #94ABBE;
    padding-top: 11px;
    padding-left: 21px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #E0E0E0;
    border-bottom: 0px;
}
.product-tab-select {
    background-color: white !important;
    border-bottom: 2px solid white !important;
    height: 53px !important;
}
.product-add-modal .modal-content {
    width: 710px;
}
.type-suggest-model {
    width: 156px;
    height: 216px;
    position: absolute;
    background-color: white;
    top: 174px;
    left:521px;
    border-left: 2px solid #E6E6E6;
    border-right: 2px solid #E6E6E6;
    border-bottom: 2px solid #E6E6E6;
}
.category-suggest-model {
    width: 362px;
    height: auto;
    max-height: 216px;
    position: absolute;
    background-color: white;
    top: 110px;
    left:56px;
    border-left: 2px solid #E6E6E6;
    border-right: 2px solid #E6E6E6;
    border-bottom: 2px solid #E6E6E6;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
    padding-top: 12px;
}
.category-suggest-model::-webkit-scrollbar {
    width: 8px;
}
.category-suggest-model::-webkit-scrollbar-track {
    background: #BDBDBD;
    border-radius: 4px;
}
.category-suggest-model::-webkit-scrollbar-thumb {
    background-color: #A4A4AC;
    border-radius: 8px;
}
.product-tile-wrapper {
    width: 361px;
    height: 55px;
    align-items: center;
    padding-top: 17px;
    padding-left: 10px !important;
}
.product-tile-wrapper:hover {
    background-color: #E0E0E0;
}
.product-tile-wrapper .type-tile {
    float: left;
}
.product-tile-wrapper .product-icon-tile {
    float: left;
    width: 30px;
    height: 30px;
    background: #4A657C;
    border-radius: 4px;
}
.category-suggest-model .type-tile {
    width: 313px;
    height: 22px;
    margin: auto;
    padding-top: 0px;
    margin-top: 5px;
    margin-left: 8px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* text - mid */
    color: #7C7C82;
}
.type-suggest-model .type-tile {
    background-color: white;
    width: 153px;
    height: 53px;
    margin: auto;
    padding-top: 15px;
}
.open-type-suggest-model {
    display: block;
}
.open-category-suggest-model {
    display: block;
}
.close-category-suggest-model {
    display: none;
}
.close-type-suggest-model {
    background-color: white;
    width: 153px;
    height: 53px;
    margin: auto;
    padding-top: 15px;
    display: none;
}
.hr-alligh {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: 0 !important;
    border-top: 1px solid rgba(177, 160, 160, 0.993) !important;
}
.hr-alligh-category {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: 0 !important;
    border-top: 1px solid rgba(177, 160, 160, 0.993) !important;
}
.specs-tab {
    float: right;
    width: 66px;
    height: 51px;
    background-color: #83b1e8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #E0E0E0;
    border-bottom: 0px
}
.specs-tab-select {
    background-color: white !important;
    border-bottom: 2px solid #FFFFFF !important;
    height: 53px !important;
}
.item-tab {
    float: right;
    width: 66px;
    height: 51px;
    background-color: #5d84b1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #E0E0E0;
    border-bottom: 0px;
    cursor: not-allowed;
    background: rgba(196, 196, 196, 0.5);
}
.item-tab-select {
    background-color: white !important;
}
.data-tab {
    float: right;
    width: 66px;
    height: 51px;
    background: rgba(196, 196, 196, 0.5);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #E0E0E0;
    border-bottom: 0px;
    cursor: not-allowed;
}
.market-tab {
    float: right;
    width: 66px;
    height: 51px;
    background: rgba(196, 196, 196, 0.5);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #E0E0E0;
    border-bottom: 0px;
    cursor: not-allowed;
}
.product-edit-modal .market-tab {
    margin-left: 15px;
}
.product-edit-modal .data-tab {
    margin-left: 15px;
}
.product-edit-modal .item-tab {
    margin-right: 15px;
}
.product-edit-modal .specs-tab {
    margin-right: 15px;
}
.product-edit-modal .privios-tab-product {
    margin-right: 15px;
    margin-top: 18px;
}
.data-tab-select {
    background-color: white !important;
}
.product-edit-modal .modal-header {
    padding-top: 7px !important;
}
.product-edit-modal .modal-header {
    padding-top: 7px !important;
    border-bottom: 2px solid #e0e0e0;
}
.product-edit-modal .model-header-wrapper-edit {
    margin-top: 0px !important;
}
.product-edit-modal .modal-body {
    padding-top: 12px !important;
}
.product-edit-modal  .breadcrumb {
    height: 24px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #7C7C82;
    padding: 0px !important;
    margin-bottom: 0px !important;
}
.next-tab-product {
    float: right;
    width: 20px;
    height: 20px;
    margin: auto;
    margin-left: 15px !important;
    margin-top: 18px !important;
    margin-right: 26px !important;
}
.privios-tab-product {
    float: right;
    width: 20px;
    height: 20px;
    margin: auto;
}
.product-tab-edit {
    float: right;
}
.poster-center {
    height: 100px;
    width: 600px;
    margin-top: 684px;
    background-color: #007647;
    margin-left: 50px;
}
.product-edit-modal .close {
    border-radius: 5px;
}
.poster-right {
    float: left;
    height: 300px;
    width: 300px;
    background-color: #007647;
    margin-top: 485px;
    margin-left: 100px !important;
}
.no-padding {
    padding-left: 0px !important;
}
.product.button-remove {
    margin: 4px 0 0 2px;
    float: left;
}
.product-tile-content {
    border-radius: 10px;
    background: #fff;
    height: 200px;
}
.product.tile.selected {
    height: 248px;
    width: 656px;
    border:8px solid #317CD3;
    margin-top: -8px;
    margin-bottom:12px;
}
.product.tile.selected .product-tile-content {
    border-radius: 10px 10px 0 0;
}
.product-tile-grid-view-main-image-wrapper {
    width: 200px;
    height: 200px;
    float: left;
    border-radius: 10px 0 0 10px;
}
.product-tile-grid-view-main-image-wrapper img {
    width: 200px;
    height: 200px;
    float: left;
    border-radius: 10px 0 0 10px;
    margin-top: -24px;
}
.navigation-wrapper {
    height: 36px;
    padding: 0 10px;
}
.product-tile-grid-view-main-image-wrapper .navigation-wrapper .counter {
    margin-top: -44px;
}
.product-tile-grid-view-main-image-wrapper .navigation-wrapper .controls {
    margin-left: 12px;
    margin-top: -44px;
    float: right;
}
.product-item.button-remove {
    margin-left: 10px;
    margin-bottom: -18px;
}
.product-tile-grid-view-item-wrapper {
    width: 440px;
    height: 100px;
    padding: 0 10px;
    float: left;
}
.product-tile-grid-view-item-thumbnail {
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 10px;
}
.product-tile-grid-view-item-thumbnail img {
    width: 100px;
    height: 100px;
}
.product-tile-grid-view-item-thumbnail .counter {
    width: 19px;
    height: 18px;
    margin-left: 8px;
    margin-top: -26px;
    background: #B7B8BA;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    content-visibility:auto;
}
.product-tile-grid-view-create-item-drop-zone {
    width: 200px;
    border:1px dashed #94ABBE;
    height: 92px;
    margin-top: 8px;
    background: url(${
    product_tile_add_item
    }) no-repeat center center;
    padding: 8px 38px 9px 38px;
    border-radius: 0px 6px 0px 0px;
    float: right;
}
.product-tile-grid-view-product-details-wrapper {
    width: 440px;
    height: 100px;
    float: right;
}
.product-tile-grid-view-item-details-wrapper .item-details {
    width: 340px;
    float: left;
    padding: 6px 4px 4px 10px;
    margin-top: 20px;
}
.product-tile-grid-view-item-details-wrapper .item-details h5{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
}
.product-tile-grid-view-item-details-wrapper .item-details .item-details-editable-label {
    padding-left: 4px;
    width: 392px;
    overflow: hidden;
    margin: 0px;
    height: 19px;
}
.product-tile-grid-view-item-details-wrapper .item-details .item-details-editable-input {
    border: 2px solid #317CD3;
    border-radius: 3px;
    padding: 2px;
    outline: none;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    width: 394px;
    height: 19px;
    margin-bottom: 4px;
}
.product-tile-grid-view-item-details-wrapper .item-details .description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    height: 19px;
    margin: 0;
}
.product-tile-grid-view-item-details-wrapper .item-details .item-description-editable-label {
    padding-left: 4px;
    width: 392px;
    overflow: hidden;
    margin: 0px;
    font-weight: normal;
    height: 19px;
    font-size: 12px;
    line-height: 19px;
    padding-top: 1px;
    cursor: text;
}
.product-tile-grid-view-item-details-wrapper .item-details .item-description-editable-input {
    border: 2px solid #317CD3;
    border-radius: 3px;
    padding: 1px 2px;
    outline: none;
    margin: none;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    width: 392px;
    height: 19px;
    float: left;
    cursor: text
}
.product-tile-grid-view-item-details-wrapper .product-tile-grid-view-item-owner-details {
    padding-bottom: 1px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #434552;
    float: right;
}
.product-tile-grid-view-item-details-wrapper .author {
    font-size:0px;
    float: right;
    margin-left: 12px;
    width: 18px;
    height: 18px;
}
.product-tile-grid-view-item-details-wrapper .author img {
    width: 18px height: 18px;
    border: 2px solid #B7B8BA;
}
.product-tile-grid-view-product-details-wrapper .product-tile-grid-view-myshed {
    float: right;
    width: 100px;
}
.product-item-add-modal {
    max-width: 800px !important;
    width: 710px;
}
.product-item-edit-modal {
    max-width: 800px !important;
    width: 710px;
}
.product-item.modal-title .h4 {
    background: #fff url(${
    product_item_add_modal_title_icon
    }) no-re
}
.product-item-add-modal .image-previews-wrapper {
    height: 115px;
}
.product-item-add-modal .image-preview-wrapper {
    width: 115px;
    height: 115px;
    background: #eee;
    padding: 0px;
    border-radius: 10px;
}
.product-item-add-modal .image-preview-wrapper .image-preview {
    max-width: 115px;
    max-height: 115px;
    border-radius: 10px;
}
.product-item-edit-modal .image-previews-wrapper {
    height: 115px;
}
.product-item-edit-modal .image-preview-wrapper {
    width: 115px;
    height: 115px;
    background: #eee;
    padding: 0px;
    border-radius: 10px;
}
.product-item-edit-modal .image-preview-wrapper .image-preview {
    max-width: 115px;
    max-height: 115px;
    border-radius: 10px;
}
.product-item-full-view-modal {
    max-width: 1200px;
    width: 1094px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
}
.product-item-full-view-modal .modal-header {
    height: 72px;
    padding: 0;
    display: block;
    width: 100%;
    margin-bottom: 43px;
    border: none;
}
.product-item-full-view-modal .button-remove-large {
    float: left;
    margin: 0;
    width: 35px;
    height: 35px;
}
.btn:hover, .btn:focus, .btn:active {
    box-shadow:none !important;
    border:none !important;
}
.product-item-full-view-modal .modal-header .title {
    background: #B7B8BA;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding: 3px 14px 5px 14px;
    width: 130px;
    overflow: hidden;
    height: 35px;
    margin-left: 14px;
    float: left;
}
.product-item-full-view-modal .modal-header .close {
    margin-right: 0;
}
.product-item-full-view-modal .product-title-editable-label {
    width: 102px;
    cursor: text;
    height: 27px;
    overflow: hidden;
}
.product-item-full-view-modal .product-title-editable-input {
    border: 2px solid #317CD3;
    border-radius: 3px;
    padding: 2px;
    outline: none;
    margin: none;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    width: 102px;
    height: 27px;
    float: left;
}
.product-item-full-view-modal .navigation-wrapper {
    width: 258px;
    float: left;
    padding: 0px;
    margin-left: 239px;
}
.product-item-full-view-modal .navigation-wrapper .counter {
    float: left;
    height: 71px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 0;
    width: 100px;
    border-radius: 10px;
}
.product-item-full-view-modal .navigation-wrapper .controls {
    float: left;
    margin-left: 14px;
    height: 72px;
    width: 144px;
    border-radius: 10px;
}
.product-item-full-view-modal .navigation-wrapper .controls .control {
    margin: 10px; /* TODO: get the correct image at 72px */
    width: 52px;
    height: 52px;
}
.product-item-full-view-modal .modal-content {
    background: transparent;
    background-color: none;
    border: none;
}
.product-item-full-view-modal .modal-body {
    padding: 0px;
}

.product-item-full-view-image-wrapper {
    height: 547px;
    overflow: auto;
    text-align: center;
    padding-right: 21px;
    scrollbar-width: thin;
    scrollbar-color: #BDBDBD #A4A4AC;
}
.product-item-full-view-image-wrapper::-webkit-scrollbar {
    width: 8px;
}
.product-item-full-view-image-wrapper::-webkit-scrollbar-track {
    background: #BDBDBD;
    border-radius: 4px;
}
.product-item-full-view-image-wrapper::-webkit-scrollbar-thumb {
    background-color: #A4A4AC;
    border-radius: 8px;
}

/* User column */

.user-column {
    width: 197px;
    float: right;
    transition: width 300ms;
}
.user.column-header {
    background: #B58151;
    padding: 0px;
}
.user.column-header .logged-in-user-thumbnail-wrapper {
    float: left;
    width: 72px;
    height: 68px;
    padding: 7px 12px 7px 6px;
     cursor: pointer;
}
.user.column-header .logged-in-user-thumbnail-wrapper img.logged-in-user-thumbnail {
    width: 48px;
    height: 48px;
    border-radius: 27px;
    border: 3px #B7B8BA solid;
}
.user.column-header .logged-in-user-thumbnail-wrapper .initials {
    width: 54px;
    height: 54px;
    border-radius: 27px;
    border: 3px #B7B8BA solid;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
    background: #D33131;
}
.user.column-header h2 {
    padding: 25px 0 27px 0;
    text-align: left;
    float: left;
    height: 68px;
    margin: 0;
    font-size: 14px !important;
}
.user-action-bar-wrapper {
    padding-left:3px;
}
.user.action-bar {
    border-radius: 34px 10px 10px 34px;
    width: 184px;
    background: #fff;
    padding: 11px 0px 11px 11px;
}
.user.action-bar .add-user-icon {
    width: 46px;
    height: 46px;
    border-radius:23px;
    background: url(${
        invite_user_icon
     }) no-repeat center;
    float: left;
}
.user.action-bar .add-user-text {
    padding: 11px 0 13px 12px;
    height: 68px;
    color: #434552;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    float: left;
}
.myprofile-edit-modal .image-previews-wrapper {
    height: 54px;
    width: 54px;
    border-radius: 50%;
    border: 3px solid #B7B8BA;
    padding: 0;
    margin-left: 37px;
}
.myprofile-edit-modal .image-preview-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 0;
}
.myprofile-edit-modal .image-preview-wrapper .image-preview {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    padding: 0;
    border: none;
}
.user.modal-title .h4 {
    background: #fff url(${
        user_add_modal_title_icon
    }) no-repeat;
}
.user.tiles-wrapper {
    background: rgba(211, 177, 143, 0.5) url(${
        user_background
    });
    padding-top: 20px;
    scrollbar-width: thin;
    scrollbar-color: #B58151 #dfc1a4;
}
.user.tiles-wrapper::-webkit-scrollbar {
    width: 8px;
}
.user.tiles-wrapper::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #dfc1a4;
}
.user.tiles-wrapper::-webkit-scrollbar-thumb {
    background-color: #B58151;
    border-radius: 8px;
}
.user-view-switches-wrapper {
    height: 24px;
    margin-bottom: -32px;
    margin-top: 8px;
}
.user .full-to-limited {
    background: url(${
        user_full_to_limited_view_icon
    }) no-repeat;
    width: 56px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    left: 71px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.user .full-to-minimized {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;   
    position: absolute;
    left: 22px;
    background: url(${
        user_full_to_minimized_view_icon
    }) no-repeat;
}
.user .limited-view-toggle-wrapper {
    background: url(${
        user_limited_view_wrapper_icon
    }) no-repeat;
    width: 56px;
    height: 24px;
    left:8px;
    position:absolute;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.user .limited-to-full {
    width: 24px;
    height: 24px;
    cursor: pointer;
    float: left;
}
.user .limited-to-minimized {
    width: 24px;
    height: 24px;
    cursor: pointer;
    float: right;
}
.user .minimized-to-limited {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: url(${
        user_minimized_to_limited_view_icon
    }) no-repeat;
    right: 0px;
    top: 224px;
    border-radius: 0px 4px 4px 0;
}
.minimize-wraper .minimized-to-full {
    width: 24px;
    height: 56px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: url(${
        user_minimized_to_full_view_icon
    }) no-repeat;
    right: 0px;
    top: 172px;
    border-radius: 0px 4px 4px 0;
}
.minimize-wraper .minimized-to-limited {
    width: 24px;
    height: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: url(${
        user_minimized_to_limited_view_icon
    }) no-repeat;
    right: 0px;
    top: 232px;
    border-radius: 0px 4px 4px 0;
}
.user .minimized-to-full {
    width: 24px;
    height: 56px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background: url(${
        user_minimized_to_full_view_icon
    }) no-repeat;
    right: 0px;
    top: 164px;
    border-radius: 0px 4px 4px 0;
}
.user-tile-wrapper {
    height:92px;
    margin-bottom:15px;
    margin-top:0px;
    padding-top:4px;
    padding-bottom:4px;
    padding-left:6px;
}
.user-tile-wrapper.selected {
    padding-top:0px;
    padding-bottom:0px;
    padding-left:2px;
}
.user-tile-wrapper.detail_view {
    height:692px;
    margin-bottom:15px;
    padding-top:40px;
    padding-bottom:0px;
    padding-left:22px;
    transition: height 400ms;
}
.user.tile {
    width: 174px;
    height: 54px;
    border-radius: 27px 10px 0px 27px;
    margin-bottom: 30px;
    /* margin-top: 4px; */
    margin: 0px;
    /* border: 4px solid #fff;
    */
}
.user.tile.selected {
    /* margin-top:0px; */
}
.user-tile-thumbnail {
    height: 54px;
    width: 54px;
    float: left;
    border: 3px solid #F57F45;
}
.user-tile-thumbnail img {
    height: 48px;
    width: 48px;
    border-radius: 24px;
}
.user-tile-thumbnail .initials {
    width: 48px;
    height: 48px;
    background: #7C7C82;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
}
.user-tile-top-content {
    width: 120px;
    height: 46px;
    margin-left: 54px;
    border-radius: 0 10px 0px 0px;
    background: #fff;
    padding: 8px 8px 6px 8px;
}
.user-tile-meta{
    width: 86px;
    float: left;
    margin-top:3px;
}
.user-tile-meta .role {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #434552;
}
.user-tile-meta .company {
    color: #7C7C82;
    font-size: 10px;
    line-height: 14px;
    margin-top: 5px;
}
.user .multi-function-menu-vertical {
    float: right;
    width: 18px;
    height: 18px;
}
.user-tile-username {
    width: 154px;
    height: 30px;
    border-radius: 0px 0px 10px 10px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: -30px;
    margin-left: 20px;
}
.user.tile .user-tile-username .content {
    border-top: 1px solid #e0e0e0;
    padding: 5px 8px 8px 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #434552;
    text-align: left;
    height: 30px;
}
.user-tile-username .notification-counter {
    width: 24px;
    height: 16px;
    background: transparent url(${
        user_tile_notification_counter_placeholder
    }) no-re float: right;
    padding-left: 10px;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #A4A4AC;
    margin-top: 1px;
}
.user.tile.selected {
    border: 4px solid #317CD3;
    width: 182px;
    border-radius: 31px 10px 0px 31px;
    height: 62px;
}
.user.tile.selected .user-tile-username {
    border: 4px solid #317CD3;
    border-top: none;
    width: 162px;
    margin-left: 16px;
    height: 34px;
}
.user-tile-initials {
    width: 46px;
    height: 30px;
    border-radius: 0px 0px 10px 10px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: -30px;
    margin-left: 20px;
}
.user.tile .user-tile-initials .content {
    border-top: 1px solid #e0e0e0;
    padding: 6px 8px 8px 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
     color: #434552;
     text-align: left;
    height: 30px;
}
.user.tile.selected .user-tile-initials {
    border: 4px solid #317CD3;
    border-top: none;
    width: 54px;
    margin-left: 16px;
    height: 34px;
}
.user-column.limited {
    width: 95px;
}
.user-column.limited .user.action-bar {
    width: 95px;
}
.user-column.limited .user.tile {
    width: 66px;
    margin-left: 0px;
    padding-left: 0px;
}
.user-column.limited .user.tile.selected {
    width: 74px;
}
.user-column.limited .user-tile-content {
     display: none;
}
.user-column.limited .user-meta{
    width: 65px;
}
.user-column.limited .user-details-wrapper {
    border: none;
    width: 65px;
    margin-left: 0px;
}
.user-column.limited  .user.tile.selected .user-details-wrapper {
    border: 6px solid #317CD3;
    border-top: none;
    height: 38px;
    margin-top: -6px;
    width: 71px;
    margin-left: 0px;
}
.user-column.limited .user-meta .plot-number {
    float:none !important;
}
.user-column.limited .create-user span {
     display: none;
}
.user-column.limited .user-action-bar-wrapper {
    width: 157px;
    padding-left: 0px;
    padding-right: 14px;
}
.user-column.limited .create-user {
    width: 74px;
    padding-left: 0px;
    border-radius: 0;
}
.user-column.limited .column-header {
    width: 95px;
    border-radius: 0 0 10px 0;
}
.user-column.limited .column-header h2 {
     display: none;
}
/* Property Tiles Minimized */
.user-column.minimized {
    width: 34px;
}
.user-column.minimized .user.tiles-wrapper {
    background: none;
}
.user-column.minimized .user.tile, .user-column.minimized .user-action-bar-wrapper, .user-column.minimized .column-header {
     display: none;
}

/////// user detail view

.user.tile.detail_view {
    margin: 0;
    border: 4px solid #94529C;
    width: 162px;
    border-radius: 10px;
    height:92px;
}
.user.tile.detail_view .user-tile-thumbnail {
    float: none;
    margin: -46px auto 0px auto;
}
.user.tile.detail_view .user-details {
    background: #CDAA89;
    width: 154px;
    height: 560px;
    border-radius: 0 0 10px 10px;
}
.user.tile.detail_view .user-tile-top-content {
    width: 154px;
    height: 46px;
    margin-left: 0px;
    border: none;
    padding-top:0px;
}
.user.tile.detail_view .user-tile-username {
    width: 154px;
    height: 34px;
    border-bottom:4px solid #94529C;
    margin: 0;
    border-radius: 0 0 10px 10px;
    box-shadow:none;
    background: #CDAA89;
}
.user.tile.detail_view .user-tile-username .content {
    padding:7px;
    color: #000;
}
.user.tile.detail_view .user-tile-username .notification-counter {
    color: #e0e0e0;
}
.user.tile.detail_view .user-tile-meta{
    padding-top: 2px;
}
.user.tile.detail_view .user-tile-meta .meta{
    text-align: center;
    width: 120px;
    height: 16px;
    padding-left: 16px;
    background: url(${
        edit_menu_link
    }) no-repeat left center;
    margin-bottom: 4px;
}
.user.tile.detail_view .user-tile-thumbnail-large {
    width: 154px;
    height: 154px;
    margin: 0;
    padding: 0;
}
.user.tile.detail_view .user-tile-thumbnail-large .initials-large {
    background: #eee;
    text-align: center;
    line-height: 154px;
    font-size: 65px
}
.user-details-title {
    color: #434552;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    padding: 1px 0 1px 21px;
    margin: 0px;
    margin-left: 6px;
}
.initials-title {
    background: url(${initials_title_icon }) no-repeat left center;
}
.email-title {
    background: url(${email_title_icon }) no-repeat left center;
}
.mobile {
    background: url(${mobile_icon }) no-repeat left center;
}
.facebook {
    background: url(${facebook_icon }) no-repeat left center;
}
.twitter {
    background: url(${twitter_icon }) no-repeat left center;
}
.linkedin {
    background: url(${linkedin_icon }) no-repeat left center;
}
.nproperties {
    background: url(${nproperties_icon }) no-repeat left center;
}
.level {
    background: url(${level_icon }) no-repeat left center;
}
.user-details-value {
    height: 26px;
    margin: 0px;
}
.user-details-value label {
    font-size: 12px;
    font-color: #000;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
    height: 26px;
    padding: 4px 2px;
    margin: 0px;
    margin-left: 8px;
}
.user-details-editable-label {
    font-size: 12px;
    font-color: #000;
    line-height: 16px;
    font-style: normal;
    font-weight: 600;
    height: 26px;
    padding: 4px 2px;
    margin: 0px;
    margin-left: 8px;
    cursor: text;
}
.user-details .action-button-wrapper {
    height: 26px;
    padding: 8px 8px 0px 4px;
    width: 154px;
}
.user-detail-element {
    margin-bottom: 8px;
    height: 40px;
}
.user-details-editable-input {
    border: 2px solid #317CD3;
    border-radius: 5px;
    padding: 5px 6px;
    outline: none;
    margin: 0 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 150px;
    height: 26px;
}

//Auto suggest

.react-autosuggest__container {
    position: relative;
}
.react-autosuggest__input {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.react-autosuggest__input--focused {
    outline: none;
}
.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
    display: none;
}
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0 11px;
}
.react-autosuggest__suggestion .suggestion-content {
    height: 50px;
    border-bottom:1px solid #E0E0E0;
    padding: 11px 0px;
}
.react-autosuggest__suggestion .text {
    font-size: 14px;
    line-height: 28px;
    color: #434552;
    padding-left: 11px;
}
.react-autosuggest__suggestion .firm {
    font-weight: bold;
    font-size: 12px;
}
.react-autosuggest__suggestion .text.invited {
    color: #83848B;
}
.react-autosuggest__suggestion .text.accepted {
    color: #83848B;
}
.react-autosuggest__suggestion .initials {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 22px;
    float: left;
    color: #fff;
}
.react-autosuggest__suggestion .logo {
    width: 28px;
    height: 28px;
    border-radius: 2px;
    float: left;
    color: #fff;
    padding: 4px;
}
.react-autosuggest__suggestion .logo img {
    width: 20px;
    height: 20px;
}
.react-autosuggest__suggestion .initials.invited {
    background: #E1E1E1;
    border: 3px solid #BDBEBF;
}
.react-autosuggest__suggestion .initials.accepted {
    background: #fff;
    border: 3px solid #BDBEBF;
    color: #BDBEBF;
}
.react-autosuggest__suggestion .initials.property_developer {
    background: #FFFFFF;
    border: 3px solid #B7B8BA;
    color: #B7B8BA;
}
.react-autosuggest__suggestion .initials.home_owner {
    background: #fff;
    border: 3px solid #6B8834;
    color: #6B8834;
}
.react-autosuggest__suggestion .initials.estate_agent {
    background: #fff;
    border: 3px solid #C9694C;
    color: #C9694C;
}
.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
.collaboratorInvite-modal .modal-content {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 60px 10px 10px 10px;
    width: 710px;
}

/**********************************/
/* Homepage */
/**********************************/

.homepage .header {
    height: 58px;
    background: #007647;
    width: 100%;
}
.homepage .header .company-logo {
    background: url(${
        company_logo_long
     }) no-repeat ;
    height: 58px;
    margin-left: 15px;
    width: 115px;
    float: left;
}
.body-content-background-wrap {

}
.homepage .header .client-logo {
    margin-left: 15px;
    float: left;
    max-width: 120px;
    max-height: 20px;
    margin-top: 19px;
}
.homepage .header .link-wrapper {
    font-size: 16px;
    line-height: 22px;
    height: 58px;
    padding: 18px 19px;
    text-align:right;
    color: #fff;
    float: right;
    width: 600px;
    font-weight: bold;
    cursor: pointer;
}
.signin-link {
    padding-left: 10px
}
.homepage .body-content-background-wrapper {
    height: 360px;
    background: url(${
    home_background
     }) no-repeat center center fixed;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/************************************/
/* Auth */
/************************************/

.signup-modal {
    width: 600px;
    max-width: 600px;
    height: 800px !important;
}
.sign-up-model-show {
    display: block;
}
.sign-up-model-hide {
    display: none;
}
.login-model-show {
    display: block;
}
.login-model-hide {
    display: none;
}
.login-form-wr {
    float: left;
    float: left;
    width: 600px;
    height: 600px;
}
.model-wrapper-si{
    left: 0;
    z-index: 10;
    top: 0;
    width: 1300px;
    min-width: 1300px;
    height: 786px;
    margin: auto;
}
.wrapper-all-model {
    width: 100%;
    left: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    height: 787px;
    margin-top: 58px;
    overflow-y: scroll;
}
.model-content-sign-up {
    position: absolute;
    width: 600px;
    height: 600px;
    left: calc(50% - 600px/2);
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 49px;
}
.close-signUp-model {
    height: 50px;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    width: 50px;
}
.close-con:hover {
    background: #fff url(${
    modal_button_close_hover
    }) no-repeat;
    height: 36px;
}
.close-con {
    background: #fff url(${
    modal_button_close
    }) no-repeat;
    height: 36px;
}
.back-color {
    /* height: 800px;
    width: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    position: fixed;
    margin-top: 108px;
    background: red;
    transition:none;
    overflow-x: hidden;
    overflow-y: auto; */
}
.signup-modal .modal-content {
    width: 600px !important;
    margin: auto !important;
}
.main-class-sign-model {
    padding-left: 0px !important;
}
.signup-modal label {
    font-size: 14px;
    line-height: 19px;
    color: #434552;
}
.signup-modal span.terms {
    font-size: 14px;
    line-height: 19px;
    color: #434552;
}
.forgotten-password-link {
    color: #7C7C82;
    cursor: pointer;
    margin-bottom:25px;
    margin-top: -9px;
    padding-left:37px;
}
.reset-password-sent {
    color: #7C7C82;
    font-size: 14px;
    line-height: 19px;
}
.submit {
    text-transform:capitalize !important;
}
.signup-submit {
    margin-top:34px;
}
.login-modal {
    width: 600px;
    max-width: 650px;
    height: 600px
}
.login-modal .btn.submit.maximum {
    margin-top: 110px;
}
.login-modal .modal-body {
    height: 540px
}
.signup-modal .modal-body {
    height: 540px
}

`;

export default GlobalStyle;
