import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import Dashboard from 'containers/Dashboard/Loadable';
import SharedContent from 'containers/SharedContent/Loadable';
import FrontPage from 'containers/FrontPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import { useInjectReducer } from 'utils/injectReducer';
import GlobalStyle from '../../global-styles';
import reducer from './reducer';

const AppWrapper = styled.div`
  height:100%; 
  min-height:100%;
  min-width:1194px">
`;

export default function App() {
  const key = 'global';
  useInjectReducer({ key, reducer });
  return (
    <AppWrapper>
      <Helmet titleTemplate="%s" defaultTitle="Shedyt">
        <meta name="description" content="app1.shedyt.com" />
      </Helmet>
      <Switch>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/password_reset" component={FrontPage} />
        <Route exact path="/activate/:invitation_code" component={FrontPage} />
        <Route exact path="/:account_id" component={FrontPage} />
        <Route exact path="/:account_id/password_reset" component={FrontPage} />
        <Route exact path="/:account_id/dashboard" component={Dashboard} />
        <Route exact path="/:account_id/shared/:sharing_code" component={SharedContent} />
        <Route exact path="/:account_id/activate/:invitation_code" component={FrontPage} />
        <Route path="" component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </AppWrapper>
  );
}
